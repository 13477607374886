<template>
  <button class="button-burger" type="button" @click="clickBurger">
    <div class="visually-hidden">Мобильное меню</div>
    <div class="button-burger__item" :class="{ 'button-burger__item_open': status }"></div>
  </button>
</template>

<script>
export default {
  name: "ButtonBurger",
  emits: ["click"],
  computed: {
    status() {
      return this.$store.state.menu
    }
  },
  methods: {
    clickBurger() {
      this.$emit("click");
    }
  }
};
</script>

<style>
.button-burger {
  position: relative;

  width: 32px;
  height: 18px;
  padding: 5px;
  border: none;

  background-color: transparent;

  cursor: pointer;
}

.button-burger__item {
  position: relative;

  width: 23px;
  height: 1px;

  background-color: #ffffff;
  transition: all 0ms 300ms;
}

.button-burger__item::before {
  content: "";

  position: absolute;
  left: 0;
  bottom: 6px;

  width: 23px;
  height: 1px;

  background-color: #ffffff;
  transition: bottom 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
}

.button-burger__item::after {
  content: "";

  position: absolute;
  left: 0;
  top: 6px;

  width: 23px;
  height: 1px;

  background-color: #ffffff;
  transition: top 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
}

.button-burger__item.button-burger__item_open {
  background: rgba(255, 255, 255, 0);
}

.button-burger__item.button-burger__item_open::before {
  bottom: 0;
  transform: rotate(-45deg);
  transition: bottom 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
}

.button-burger__item.button-burger__item_open::after {
  top: 0;
  transform: rotate(45deg);
  transition: top 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
}
</style>
