<template>
  <a class="button-menu">
    <slot />
  </a>
</template>

<script>
export default {
  name: "ButtonMenu",
};
</script>

<style>
.button-menu {
  position: relative;

  padding: 0 20px 20px 20px;
  border: none;

  font-family: 'Constantia', Tahoma, sans-serif;
  font-size: 17px;
  line-height: 1.5;
  text-align: center;
  color: #ffffff;
  text-decoration: none;

  background-color: transparent;
  cursor: pointer;
}

.button-menu::after {
  content: "";

  position: absolute;
  left: 0;
  bottom: 0;

  width: 100%;
  height: 11px;

  background-image: url(@/assets/img/menu.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;

  transition: transform 0.3s ease;
}

.button-menu:hover::after {
  transform: translateY(-3px);
}

@media (max-width: 1599.98px) {
  .button-menu {
    padding: 0 18px 18px 18px;

    font-size: 15px;
  }

  .button-menu::after {
    height: 10px;
  }
}

@media (max-width: 1199.98px) {
  .button-menu {
    padding: 0 17px 17px 17px;

    font-size: 14px;
  }

  .button-menu::after {
    height: 9px;
  }
}
</style>