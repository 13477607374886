<template>
  <component class="title-1" :is="tag">
    <slot />
  </component>
</template>

<script>
export default {
  name: "Title1App",
  props: {
    tag: {
      // Определяет возвращаемый тег, по умолчанию h1
      type: String,
      default: "h1",
    },
  },
};
</script>

<style>
.title-1 {
  font-family: 'Constantia', Tahoma, sans-serif;
  font-size: 80px;
  line-height: 1.3;
  color: #ffffff;
  font-weight: normal;
}

@media screen and (max-width: 1919.98px) {
  .title-1 {
    font-size: 72px;
  }
}

@media screen and (max-width: 1599.98px) {
  .title-1 {
    font-size: 60px;
  }
}

@media screen and (max-width: 1199.98px) {
  .title-1 {
    font-size: 52px;
  }
}

@media screen and (max-width: 991.98px) {
  .title-1 {
    font-size: 42px;
  }
}

@media screen and (max-width: 767.98px) {
  .title-1 {
    font-size: 30px;
  }
}
</style>
