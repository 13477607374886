<template>
  <form class="сallback-booking">
    <div class="сallback-booking__fields">
      <Input class="сallback-booking__name" :status="errors.name ? 'error': ''" label="Ваше имя" placeholder="Имя"
        v-model="name" required />
      <Input class="сallback-booking__phone" :status="errors.phone ? 'error': ''" label="Ваш телефон"
        placeholder="Телефон" v-model="phone" v-maska="'+7 (###) ###-##-##'" required />
    </div>
    <div class="сallback-booking-control">
      <Button class="сallback-booking__bnt" @click.prevent="submit">{{ getValue('bnt') }}</Button>
      <p class="сallback-booking__desc">Нажимая “{{ getValue('bnt') }}” вы даете согласие на обработку персональных
        данных</p>
    </div>
  </form>
</template>

<script>
import Input from "@/components/element/InputApp.vue";
import Button from "@/components/element/ButtonApp.vue";
import { getValue } from '@/helpers/index.js';
import { validation } from "@/helpers/index.js";

export default {
  name: 'BookingForm',
  components: {
    Input,
    Button,
  },
  props: {
    content: {
      required: true,
      default: () => ({}),
    },
  },
  data() {
    return {
      name: "",
      phone: "",
      errors: {
        name: false,
        phone: false,
      }
    }
  },
  computed: {
    getValue() {
      return (path) => {
        return getValue(this.content, path, "");
      }
    },
  },
  methods: {
    submit() {
      let isSubmit = true;
      this.errors = { name: false, phone: false };

      if (!validation(this.name, null)) {
        this.errors.name = true;
        isSubmit = false;
      }

      if (!validation(this.phone, 'phone')) {
        this.errors.phone = true;
        isSubmit = false;
      }

      if (isSubmit) {
        this.$store.dispatch("submitForm", {
          id: 762,
          custom_name: this.name,
          custom_phone: this.phone,
        });

        this.$store.commit('updatePopup', {
          status: 'success',
          data: this.getValue('success')
        });

        let context = this;

        setTimeout(() => {
          context.$store.commit('updateClose', null);
        }, 2000)

      }
    }
  }
};
</script>
<style>
.сallback-booking__fields {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 50px;
}

.сallback-booking__quantity {
  width: 30% !important;
  margin-bottom: 40px;
}

.сallback-booking__date {
  width: 30%;
  margin-bottom: 40px;
}

.сallback-booking__time {
  width: 30%;
  margin-bottom: 40px;
}

.сallback-booking__name {
  width: 47%;
}

.сallback-booking__phone {
  width: 47%;
}

.сallback-booking-control {
  display: flex;
  align-items: center;
}

.сallback-booking__bnt {
  margin-right: 100px;
}

.сallback-booking__desc {
  width: 362px;

  font-size: 14px;
  color: #858993;
}

@media screen and (max-width: 1919.98px) {
  .сallback-booking__fields {
    margin-bottom: 45px;
  }

  .сallback-booking__quantity {
    margin-bottom: 30px;
  }

  .сallback-booking__date {
    margin-bottom: 30px;
  }

  .сallback-booking__time {
    margin-bottom: 30px;
  }

  .сallback-booking__bnt {
    margin-right: 75px;
  }

  .сallback-booking__desc {
    width: 300px;
  }
}

@media screen and (max-width: 1599.98px) {
  .сallback-booking__fields {
    margin-bottom: 40px;
  }

  .сallback-booking__quantity {
    margin-bottom: 25px;
  }

  .сallback-booking__date {
    margin-bottom: 25px;
  }

  .сallback-booking__time {
    margin-bottom: 25px;
  }

  .сallback-booking__bnt {
    margin-right: 58px;
  }

  .сallback-booking__desc {
    width: 240px;

    font-size: 12px;
  }
}

@media screen and (max-width: 1199.98px) {
  .сallback-booking__fields {
    margin-bottom: 35px;
  }

  .сallback-booking__quantity {
    margin-bottom: 20px;
  }

  .сallback-booking__date {
    margin-bottom: 20px;
  }

  .сallback-booking__time {
    margin-bottom: 20px;
  }

  .сallback-booking__desc {
    font-size: 10px;
  }
}

@media screen and (max-width: 991.98px) {
  .сallback-booking__fields {
    margin-bottom: 30px;
  }

  .сallback-booking__fields {
    flex-direction: column;
  }

  .сallback-booking__quantity {
    width: 100% !important;
  }

  .сallback-booking__date {
    width: 100%;
  }

  .сallback-booking__time {
    width: 100%;
  }

  .сallback-booking__name {
    width: 100%;
    margin-bottom: 20px;
  }

  .сallback-booking__phone {
    width: 100%;
  }

  .сallback-booking-control {
    flex-direction: column;
    align-items: flex-start;
  }

  .сallback-booking__bnt {
    margin-right: 0;
    margin-bottom: 15px;
  }
}

@media screen and (max-width: 767.98px) {
  .сallback-booking__fields {
    margin-bottom: 25px;
  }
}
</style>
