<template>
  <li class="item-reviews">
    <div class="item-reviews__wrapper">
      <Title4 class="item-reviews__name" v-html="getValue('name')" />
      <div class="item-reviews__content" ref="content">
        <div class="item-reviews__text content" v-html="getValue('text')"></div>
      </div>
      <Title4 class="item-reviews__name" tag="time" v-html="getValue('date')" />
    </div>
  </li>
</template>

<script>
import Title4 from "@/components/element/Title4App.vue";
import { getValue } from '@/helpers/index.js';

export default {
  name: 'ItemReviews',
  components: {
    Title4
  },
  props: {
    content: {
      // Определяет данные для отображения
      required: true,
      default: () => ({}),
    },
  },
  computed: {
    getValue() {
      return (path) => {
        return getValue(this.content, path, "");
      }
    },
  },
};
</script>

<style>
.item-reviews {
  position: relative;
  z-index: 0;

  padding: 30px;

  background-color: #1f2637;
}

.item-reviews::before,
.item-reviews::after,
.item-reviews__wrapper::before,
.item-reviews__wrapper::after {
  content: "";

  position: absolute;

  width: 47px;
  height: 26px;

  background-image: url('@/assets/img/reviews-icon.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.item-reviews::before {
  top: 26px;
  left: 14px;

  transform: rotate(-45deg);
}

.item-reviews::after {
  bottom: 26px;
  left: 14px;

  transform: rotate(-135deg);
}

.item-reviews__wrapper {
  padding: 30px;
  border: 1px solid #735147;
}

.item-reviews__wrapper::before {
  top: 26px;
  right: 14px;

  transform: rotate(45deg);
}

.item-reviews__wrapper::after {
  bottom: 26px;
  right: 14px;

  transform: rotate(135deg);
}

.item-reviews__name {
  margin-bottom: 27px;
}

.item-reviews__content {
  position: relative;
  z-index: 0;

  height: 130px;
  margin-bottom: 20px;
}

.item-reviews__content::after {
  content: "";

  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;

  width: 100%;
  height: 30px;

  background-image: linear-gradient(180deg, rgba(31, 38, 55, 0) 0%, #1F2637 100%);
}

.simplebar-track.simplebar-vertical {
  position: absolute;
  right: -37px;
  top: 0px;
  width: 12px;
  border: 1px solid #735147;

  background-color: #1f2637;
}

.simplebar-scrollbar::before {
  opacity: 1;
  background-color: #735147;
  border-radius: 0;
}

.simplebar-track.simplebar-vertical .simplebar-scrollbar::before {
  top: 4px;
  bottom: 6px;
}

.simplebar-scrollbar.simplebar-visible::before {
  opacity: 1;
}

@media screen and (max-width: 1919.98px) {
  .item-reviews {
    padding: 25px;
  }

  .item-reviews::before {
    top: 20px;
    left: 12px;
  }

  .item-reviews::after {
    bottom: 20px;
    left: 12px;
  }

  .item-reviews__wrapper {
    padding: 25px;
  }

  .item-reviews__wrapper::before {
    top: 20px;
    right: 12px;
  }

  .item-reviews__wrapper::after {
    bottom: 20px;
    right: 12px;
  }

  .item-reviews__content {
    margin-bottom: 15px;
  }

  .item-reviews__name {
    margin-bottom: 20px;
  }

  .simplebar-track.simplebar-vertical {
    right: -32px;
  }
}

@media screen and (max-width: 1599.98px) {
  .item-reviews {
    padding: 20px;
  }

  .item-reviews::before,
  .item-reviews::after,
  .item-reviews__wrapper::before,
  .item-reviews__wrapper::after {
    width: 37px;
    height: 20px;
  }

  .item-reviews::before {
    top: 16px;
    left: 10px;
  }

  .item-reviews::after {
    bottom: 16px;
    left: 10px;
  }

  .item-reviews__wrapper {
    padding: 20px;
  }

  .item-reviews__wrapper::before {
    top: 16px;
    right: 10px;
  }

  .item-reviews__wrapper::after {
    bottom: 16px;
    right: 10px;
  }

  .item-reviews__content {
    height: 135px;
    margin-bottom: 10px;
  }

  .item-reviews__name {
    margin-bottom: 15px;
  }

  .simplebar-track.simplebar-vertical {
    right: -26px;
    width: 11px;
  }
}

@media screen and (max-width: 1199.98px) {
  .item-reviews {
    padding: 15px;
  }

  .item-reviews::before,
  .item-reviews::after,
  .item-reviews__wrapper::before,
  .item-reviews__wrapper::after {
    width: 32px;
    height: 18px;
  }

  .item-reviews::before {
    top: 12px;
    left: 6px;
  }

  .item-reviews::after {
    bottom: 12px;
    left: 6px;
  }

  .item-reviews__wrapper {
    padding: 20px;
  }

  .item-reviews__wrapper::before {
    top: 12px;
    right: 6px;
  }

  .item-reviews__wrapper::after {
    bottom: 12px;
    right: 6px;
  }

  .item-reviews__content {
    height: 97px;
    margin-bottom: 15px;
  }

  .item-reviews__name {
    margin-bottom: 10px;
  }

  .simplebar-track.simplebar-vertical {
    right: -26px;
    width: 11px;
  }
}

@media screen and (max-width: 991.98px) {
  .item-reviews {
    padding: 10px;
  }

  .item-reviews::before,
  .item-reviews::after,
  .item-reviews__wrapper::before,
  .item-reviews__wrapper::after {
    width: 26px;
    height: 16px;
  }

  .item-reviews::before {
    top: 6px;
    left: 2px;
  }

  .item-reviews::after {
    bottom: 6px;
    left: 2px;
  }

  .item-reviews__wrapper {
    padding: 20px;
  }

  .item-reviews__wrapper::before {
    top: 6px;
    right: 2px;
  }

  .item-reviews__wrapper::after {
    bottom: 6px;
    right: 2px;
  }

  .item-reviews__content {
    height: 77px;
    margin-bottom: 15px;
  }

  .simplebar-track.simplebar-vertical {
    right: -25px;
    width: 8px;
  }
}

@media screen and (max-width: 767.98px) {
  .item-reviews__content {
    height: 154px;
    margin-bottom: 10px;
  }
}
</style>
