<template>
  <section class="about">
    <div class="container container__about" id="about">
      <Title2 class="about__title" v-html="getValue('title')" />
      <div class="about__media-1">
        <picture class="about__picture">
          <source :srcset="getValue('img.sizes.about_1_768')" media="(max-width: 991px)">
          <source :srcset="getValue('img.sizes.about_1_992')" media="(max-width:1199px)">
          <source :srcset="getValue('img.sizes.about_1_1200')" media="(max-width:1599px)">
          <source :srcset="getValue('img.sizes.about_1_1600')" media="(max-width:1919px)">
          <source :srcset="getValue('img.sizes.about_1_1920')" media="(min-width:1920px)">
          <img class="about__img-1" width="490" height="443" :src="getValue('img.url')" alt="" data-aos="animation-img">
        </picture>
      </div>
      <div class="about__inner">
        <div class="about__media-2">
          <picture class="about__picture">
            <source :srcset="getValue('img2.sizes.about_2_768')" media="(max-width: 991px)">
            <source :srcset="getValue('img2.sizes.about_2_992')" media="(max-width:1199px)">
            <source :srcset="getValue('img2.sizes.about_2_1200')" media="(max-width:1599px)">
            <source :srcset="getValue('img2.sizes.about_2_1600')" media="(max-width:1919px)">
            <source :srcset="getValue('img2.sizes.about_2_1920')" media="(min-width:1920px)">
            <img class="about__img-2" width="620" height="617" :src="getValue('img2.url')" alt=""
              data-aos="animation-img2">
          </picture>
        </div>
        <p class="about__desc content" v-html="getValue('text')" />
      </div>
    </div>
  </section>
</template>

<script>
import Title2 from "@/components/element/Title2App.vue";
import { getValue } from '@/helpers/index.js';

export default {
  name: 'AboutApp',
  components: {
    Title2,
  },
  props: {
    content: {
      required: true,
      default: () => ({}),
    },
  },
  computed: {
    getValue() {
      return (path) => {
        return getValue(this.content, path, "");
      }
    },
  },
};
</script>

<style>
.container__about {
  position: relative;
}

.about__title {
  position: absolute;
  top: 90px;
  left: 50%;
  z-index: 99;

  display: flex;
  flex-direction: column;
  width: 1067px;

  text-transform: uppercase;

  transform: translateX(-50%);
}

.about__title span {
  display: block;
}

.about__title span:nth-child(2) {
  margin-left: 280px;
}

.about__title span:nth-child(3) {
  margin-left: 539px;
}

.about__title span:nth-child(4) {
  margin-left: 148px;
}

.about__title span:nth-child(5) {
  margin-left: auto;
}

.about__media-1 {
  display: flex;
  justify-content: flex-end;
  flex-shrink: 0;
}

.about__img-1 {
  display: block;
  object-fit: cover;
  object-position: center;
}

.about__inner {
  display: flex;
  align-items: flex-end;
  margin-top: -103px;
}

.about__media-2 {
  flex-shrink: 0;
  width: 620px;
  margin-right: 280px;
}

.about__img-2 {
  display: block;
  object-fit: cover;
  object-position: center;
}

.about__desc {
  width: 493px;
  padding-top: 330px;
}

@media screen and (max-width: 1919.98px) {
  .about__title {
    top: 60px;

    width: 1035px;

    transform: translateX(-45%);
  }

  .about__img-1 {
    width: 448px;
    height: 405px;
  }

  .about__inner {
    margin-top: -80px;
  }

  .about__media-2 {
    width: 565px;
    margin-right: 338px;
  }

  .about__img-2 {
    width: 565px;
    height: 563px;
  }

  .about__desc {
    width: 430px;
    padding-top: 278px;
  }
}

@media screen and (max-width: 1599.98px) {
  .about__title {
    top: 75px;

    width: 752px;

    transform: translateX(-50%);
  }

  .about__title span:nth-child(2) {
    margin-left: 212px;
  }

  .about__title span:nth-child(3) {
    margin-left: 396px;
  }

  .about__title span:nth-child(4) {
    margin-left: 92px;
  }

  .about__img-1 {
    width: 338px;
    height: 305px;
  }

  .about__inner {
    margin-top: -45px;
  }

  .about__media-2 {
    width: 430px;
    margin-right: 193px;
  }

  .about__img-2 {
    width: 430px;
    height: 502px;
  }

  .about__desc {
    width: 430px;
    padding-top: 248px;
  }
}

@media screen and (max-width: 1199.98px) {
  .about__title {
    top: 75px;

    width: 586px;

    transform: translateX(-50%);
  }

  .about__title span:nth-child(2) {
    margin-left: 160px;
  }

  .about__title span:nth-child(3) {
    margin-left: 245px;
  }

  .about__title span:nth-child(4) {
    margin-left: 50px;
  }

  .about__img-1 {
    width: 302px;
    height: 273px;
  }

  .about__media-2 {
    width: 385px;
    margin-right: 135px;
  }

  .about__img-2 {
    width: 385px;
    height: 450px;
  }

  .about__desc {
    padding-top: 200px;
    padding-bottom: 30px;
  }
}

@media screen and (max-width: 991.98px) {
  .about__title {
    top: 25px;

    width: 528px;

    transform: translateX(-45%);
  }

  .about__title span:nth-child(2) {
    margin-left: 128px;
  }

  .about__title span:nth-child(3) {
    margin-left: 242px;
  }

  .about__title span:nth-child(4) {
    margin-left: 40px;
  }

  .about__img-1 {
    width: 154px;
    height: 139px;
  }

  .about__inner {
    align-items: flex-start;
    flex-direction: column;
  }

  .about__media-2 {
    width: 181px;
    margin-right: 0;
    margin-bottom: 30px;
  }

  .about__img-2 {
    width: 181px;
    height: 212px;
  }

  .about__desc {
    width: 100%;
    padding-top: 0;
    padding-bottom: 0;
  }
}

@media screen and (max-width: 767.98px) {
  .about__title {
    top: 35px;

    width: 380px;

    transform: translateX(-45%);
  }

  .about__title span:nth-child(2) {
    margin-left: 110px;
  }

  .about__title span:nth-child(3) {
    margin-left: 210px;
  }

  .about__title span:nth-child(4) {
    margin-left: 50px;
  }
}

@media screen and (max-width: 575.98px) {
  .about__title {
    top: 25px;

    max-width: 320px;
    width: 90%;

    transform: translateX(-50%);
  }

  .about__title span:nth-child(2) {
    margin-left: 60px;
  }

  .about__title span:nth-child(3) {
    margin-left: 140px;
  }

  .about__title span:nth-child(4) {
    margin-left: 25px;
  }

  .about__img-1 {
    width: 108px;
    height: 97px;
  }

  .about__inner {
    margin-top: 30px;
  }

  .about__media-2 {
    width: 138px;
    margin-right: 0;
    margin-bottom: 30px;
  }

  .about__img-2 {
    width: 138px;
    height: 160px;
  }

  .about__desc {
    width: 100%;
    padding-top: 0;
    padding-bottom: 0;
  }
}
</style>