<template>
  <div class="input-text" :class="classObject">
    <label>
      <input class="input-text__field" type="text" :placeholder="getPlaceholder" :value="modelValue"
        @input="$emit('update:modelValue', $event.target.value)" />
      <span class="input-text_icon"></span>
      <span class="visually-hidden">{{ label }}</span>
    </label>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: ""
    },
    placeholder: {
      type: String,
      default: ""
    },
    required: {
      type: Boolean,
      default: false
    },
    status: {
      type: String,
      default: ""
    },
    modelValue: {
      type: String,
      default: ""
    },
  },
  emits: ["update:modelValue"],
  computed: {
    classObject() {
      return {
        'input-text_errors': this.status == "error" ? true : false,
        'input-text_success': this.status == "success" ? true : false,
      }
    },
    getPlaceholder() {
      if (this.status == "error") {
        return 'Поле “' + this.placeholder + '*” не указано';
      } else {
        return this.required ? this.placeholder + "*" : this.placeholder;
      }
    }
  }
};
</script>

<style>
.input-text {
  position: relative;
  z-index: 0;

  width: 100%;

  overflow: hidden;
}

.input-text::after {
  content: "";

  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;

  width: 100%;
  height: 1px;

  background-color: rgba(255, 255, 255, 0.3);
  z-index: 1;
}

.input-text::before {
  content: "";

  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 2;

  width: 0;
  height: 1px;

  background-color: #ffffff;

  transition: width 0.5s ease;
}

.input-text:focus-within::before {
  width: 100%;
}

.input-text_success::before {
  width: 100%;

  background-color: #4fba70;
}

.input-text_errors::before {
  width: 100%;

  background-color: #d92641;
}

.input-text_icon {
  position: absolute;
  right: 11px;
  top: 3px;

  width: 21px;
  height: 21px;
  border-radius: 50%;

  background-color: #d92641;
  background-image: url(@/assets/img/errors.svg);
  background-position: center;
  background-repeat: no-repeat;

  transform: translateX(300%);
  transition: transform 0.3s ease;
}

.input-text_errors .input-text_icon {
  transform: translateX(0);
}

.input-text__field {
  display: block;
  width: 100%;
  padding: 0 32px 15px 15px;
  border: none;

  font-family: 'Montserrat', Tahoma, sans-serif;
  font-size: 14px;
  line-height: 1.5;
  color: #929292;

  background-color: transparent;

  outline: none;
  box-shadow: none;
  appearance: none;
}

.input-text_errors .input-text__field::placeholder {
  color: #d92641;
}

@media screen and (max-width: 1599.98px) {
  .input-text_icon {
    right: 10px;
    top: 4px;

    width: 18px;
    height: 18px;
  }

  .input-text__field {
    padding: 0 32px 15px 15px;
    font-size: 12px;
  }
}

@media screen and (max-width: 1199.98px) {
  .input-text_icon {
    right: 8px;
    top: 5px;

    width: 17px;
    height: 17px;
  }

  .input-text__field {
    padding: 0 24px 12px 12px;
  }
}

@media screen and (max-width: 767.98px) {
  .input-text__field {
    padding: 0 24px 10px 10px;
  }
}
</style>
