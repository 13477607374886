<template>
  <section class="contacts" id="contacts">
    <div class="container-contacts">
      <div class="contacts__info">
        <Title2 class="contacts__title" v-html="getValue('title')" />
        <div class="contacts__item">
          <p class="contacts__name">адрес</p>
          <Title4 class="contacts__value" tag="a" :href="getValue('adress.link')" target="_blank"
            v-html="getValue('adress.text')"></Title4>
        </div>
        <div class="contacts__item">
          <p class="contacts__name">режим работы</p>
          <Title4 class="contacts__value" v-html="getValue('mode')"></Title4>
        </div>
        <div class="contacts__item">
          <p class="contacts__name">телефон</p>
          <Title4 class="contacts__value contacts__phone" tag="div" v-html="getPhone"></Title4>
        </div>
        <Button class="contacts__btn" v-html="getValue('btn.text')" @click="popup" />
      </div>
      <div class="contacts__map" v-html="getValue('map')"></div>
    </div>
  </section>
</template>

<script>
import Title2 from "@/components/element/Title2App.vue";
import Title4 from "@/components/element/Title4App.vue";
import Button from "@/components/element/ButtonApp.vue";
import { getValue, getMinificationPhone } from '@/helpers/index.js';

export default {
  name: 'OfferApp',
  components: {
    Title2,
    Title4,
    Button
  },
  props: {
    content: {
      required: true,
      default: () => ({}),
    },
  },
  computed: {
    getValue() {
      return (path) => {
        return getValue(this.content, path, "");
      }
    },
    getPhone() {
      let phone = this.getValue('phone_list');
      let value = "";

      if (phone.length) {
        phone.forEach((item) => {
          let formatPhone = getMinificationPhone(item.phone);
          value += '<a href="tel:' + formatPhone + '" target="_blank">' + item.phone + '</a>';
        });
      }

      return value;
    },
  },
  methods: {
    popup() {
      this.$store.commit('updatePopup', {
        status: 'сallback',
        data: {
          popup: this.getValue('popup'),
          success: this.getValue('success'),
        }
      });
    }
  }
};
</script>

<style>
.container-contacts {
  display: flex;
  margin-left: calc((100% - var(--container)) / 2);
}

.contacts__info {
  flex-shrink: 0;
  width: 490px;
  margin-right: 290px;
}

.contacts__title {
  margin-bottom: 50px;

  text-transform: uppercase;
}

.contacts__item {
  display: flex;
  flex-direction: column-reverse;
  margin-bottom: 50px;
}

.contacts__name {
  color: #858993;
}

.contacts__value {
  margin-bottom: 5px;
}

a.contacts__value {
  text-decoration: none;

  transition: color 0.3s ease;
}

a.contacts__value:hover {
  color: #735147;
}

.contacts__phone a {
  text-decoration: none;
  color: #ffffff;

  transition: color 0.3s ease;
}

.contacts__phone a:hover {
  color: #735147;
}

.contacts__phone a::after {
  content: ', ';
}

.contacts__phone a:last-child::after {
  display: none;
}

.contacts__map {
  position: relative;

  width: 100%;
  max-height: 556px;
}

.contacts__map::before {
  content: "";

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;

  width: 100%;
  height: 100%;
  border: 65px solid;
  border-image-source: url("@/assets/img/map-border.png");
  border-image-slice: 65;
  border-image-width: 65px;

  pointer-events: none;
}

.contacts__map::after {
  content: "";

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  width: 100%;
  height: 100%;

  background-color: rgba(0, 0, 0, 0.2);

  pointer-events: none;
}

.contacts__map iframe {
  position: absolute;
  top: 0;
  height: 100%;
}

.contacts__map .map-widget-layout-view__controls {
  display: none !important;
}

@media screen and (max-width: 1919.98px) {
  .contacts__info {
    width: 381px;
    margin-right: 214px;
  }

  .contacts__title {
    margin-bottom: 45px;
  }

  .contacts__item {
    margin-bottom: 45px;
  }

  .contacts__map {
    height: 514px;

    outline-offset: -25px;
  }
}

@media screen and (max-width: 1599.98px) {
  .contacts__info {
    width: 338px;
    margin-right: 127px;
  }

  .contacts__title {
    margin-bottom: 40px;
  }

  .contacts__item {
    margin-bottom: 40px;
  }

  .contacts__value {
    margin-bottom: 0px;
  }

  .contacts__map {
    height: 438px;

    outline-offset: -20px;
  }

  .contacts__map::before {
    border: 50px solid;
    border-image-source: url("@/assets/img/map-border.png");
    border-image-slice: 65;
    border-image-width: 50px;
  }
}

@media screen and (max-width: 1199.98px) {
  .contacts__info {
    width: 280px;
    margin-right: 127px;
  }

  .contacts__title {
    margin-bottom: 35px;
  }

  .contacts__item {
    margin-bottom: 35px;
  }

  .contacts__map {
    height: 408px;

    outline-offset: -15px;
  }
}

@media screen and (max-width: 991.98px) {
  .container-contacts {
    margin-right: calc((100% - var(--container)) / 2);
  }

  .contacts__info {
    margin-right: 27px;
  }

  .contacts__title {
    margin-bottom: 20px;
  }

  .contacts__item {
    margin-bottom: 35px;
  }

  .contacts__map {
    height: 362px;

    outline-offset: -10px;
  }

  .contacts__map::before {
    border: 40px solid;
    border-image-source: url("@/assets/img/map-border.png");
    border-image-slice: 65;
    border-image-width: 40px;
  }
}

@media screen and (max-width: 767.98px) {
  .contacts__info {
    margin-right: 15px;
  }
}

@media screen and (max-width: 575.98px) {
  .container-contacts {
    flex-direction: column;
    padding: 0 15px;
  }

  .contacts__info {
    width: 100%;
    margin-right: 0;
    margin-bottom: 25px;
  }

  .contacts__title {
    margin-bottom: 25px;
  }

  .contacts__map {
    width: 100%;
    height: 300px;
  }
}
</style>
