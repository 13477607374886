import { createApp } from "vue";
import App from "./App.vue";
import Store from "@/store/index.js";
import VueScrollTo from "vue-scrollto";
import Maska from "maska";
import "@/assets/css/st-global-v1.css";

window.siteApi = "https://cafe-portos.ru/api";

let app = createApp(App).use(Store).use(VueScrollTo).use(Maska);

app.mount("#app");
