<template>
  <section class="recommend" id="menu">
    <div class="container">
      <Title2 class="recommend__title" v-html="getValue('title')" />
      <div class="recommend__wrapper">
        <div class="recommend__info">
          <p class="recommend__content content" v-html="getValue('subtitle')"></p>
          <ButtonMenu
            class="recommend__bnt-menu"
            v-if="getValue('bnt.link')"
            v-html="getValue('bnt.text')"
            :href="getValue('bnt.link')"
            target="_blank"></ButtonMenu>
        </div>
        <div class="recommend__swiper-wrapper">
          <div class="recommend__swiper swiper">
            <ul class="recommend__list swiper-wrapper">
              <li class="recommend__item swiper-slide" v-for="item in getValue('list')" :key="item.name">
                <div class="recommend__item-wrapper">
                  <img class="recommend__img" :src="item.img" alt="" />
                  <div class="recommend__item-inner">
                    <Title4 class="recommend__name" v-html="item.name" />
                    <Button class="recommend__bnt" @click="popup">{{ getButton(item.bnt) }}</Button>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div class="recommend_controls">
            <ButtonControls class="recommend_controls-prev button-controls_prev" />
            <ButtonControls class="recommend_controls-next" />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Title2 from "@/components/element/Title2App.vue";
import Title4 from "@/components/element/Title4App.vue";
import ButtonMenu from "@/components/element/ButtonMenu.vue";
import Button from "@/components/element/ButtonApp.vue";
import ButtonControls from "@/components/element/ButtonControls.vue";
import { getValue } from "@/helpers/index.js";
import Swiper, { Navigation } from "swiper";
import "swiper/css";

export default {
  name: "RecommendApp",
  components: {
    Title2,
    ButtonMenu,
    Button,
    Title4,
    ButtonControls,
  },
  props: {
    content: {
      // Определяет данные для отображения
      required: true,
      default: () => ({}),
    },
  },
  computed: {
    getValue() {
      return (path) => {
        return getValue(this.content, path, "");
      };
    },
    getButton() {
      return (path) => {
        return path.bnt ? path.bnt : "Забронировать стол";
      };
    },
  },
  methods: {
    popup() {
      this.$store.commit("updatePopup", {
        status: "booking",
        data: {
          popup: this.getValue("popup"),
          success: this.getValue("success"),
        },
      });
    },
  },
  mounted() {
    new Swiper(".recommend__swiper", {
      slidesPerView: "auto",
      modules: [Navigation],
      speed: 800,
      navigation: {
        nextEl: ".recommend_controls-next",
        prevEl: ".recommend_controls-prev",
      },
      on: {
        transitionEnd: function () {
          console.log("swiper initialized");
        },
      },
      breakpoints: {
        320: {
          spaceBetween: 15,
        },
        767: {
          spaceBetween: 20,
        },
        992: {
          spaceBetween: 30,
        },
      },
    });
  },
};
</script>

<style>
.recommend__title {
  margin-bottom: 50px;

  text-transform: uppercase;
}

.recommend__wrapper {
  display: flex;
  justify-content: space-between;
}

.recommend__info {
  flex-shrink: 0;
  width: 360px;
}

.recommend__content {
  margin-bottom: 50px;
}

.recommend__swiper-wrapper {
  position: relative;
  z-index: 0;

  width: 1140px;

  overflow: hidden;
}

.recommend__swiper {
  width: 750px;
  margin: 0;

  overflow: visible;
}

.recommend__item {
  width: 750px;
  min-height: 535px;
}

.recommend__item-wrapper {
  width: 360px;
  transition: width 0.8s ease;
}

.recommend__img {
  margin-bottom: 28px;
}

.recommend__item-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.recommend__bnt {
  display: none;
}

.recommend__item.swiper-slide-active .recommend__bnt {
  display: block;
}

.recommend__item.swiper-slide-active .recommend__item-wrapper {
  width: 750px;
}

.recommend__item.swiper-slide-prev .recommend__item-wrapper,
.recommend__item.swiper-slide-duplicate-prev .recommend__item-wrapper {
  width: 750px;
}

.recommend_controls {
  position: absolute;
  bottom: 0;
  right: 20px;
  z-index: 1;

  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 196px;
  margin: 0 auto;
}

@media screen and (max-width: 1919.98px) {
  .recommend__title {
    margin-bottom: 45px;
  }

  .recommend__info {
    width: 327px;
  }

  .recommend__content {
    margin-bottom: 45px;
  }

  .recommend__swiper-wrapper {
    width: 1040px;
  }

  .recommend__swiper {
    width: 750px;
  }

  .recommend__item {
    width: 684px;
    min-height: 487px;
  }

  .recommend__item-wrapper {
    width: 327px;
  }

  .recommend__img {
    margin-bottom: 20px;
  }

  .recommend__item.swiper-slide-active .recommend__item-wrapper {
    width: 684px;
  }

  .recommend__item.swiper-slide-prev .recommend__item-wrapper,
  .recommend__item.swiper-slide-duplicate-prev .recommend__item-wrapper {
    width: 684px;
  }

  .recommend_controls {
    right: 25px;

    width: 166px;
  }
}

@media screen and (max-width: 1599.98px) {
  .recommend__title {
    margin-bottom: 40px;
  }

  .recommend__info {
    width: 327px;
  }

  .recommend__content {
    margin-bottom: 45px;
  }

  .recommend__swiper-wrapper {
    width: 706px;
    padding-bottom: 90px;
  }

  .recommend__swiper {
    width: 522px;
  }

  .recommend__item {
    width: 522px;
    min-height: 373px;
  }

  .recommend__item-wrapper {
    width: 154px;
  }

  .recommend__img {
    margin-bottom: 15px;
  }

  .recommend__item.swiper-slide-active .recommend__item-wrapper {
    width: 522px;
  }

  .recommend__item.swiper-slide-prev .recommend__item-wrapper,
  .recommend__item.swiper-slide-duplicate-prev .recommend__item-wrapper {
    width: 522px;
  }

  .recommend_controls {
    right: 150px;

    width: 150px;
  }
}

@media screen and (max-width: 1199.98px) {
  .recommend__title {
    margin-bottom: 35px;
  }

  .recommend__info {
    width: 302px;
  }

  .recommend__content {
    margin-bottom: 35px;
  }

  .recommend__swiper-wrapper {
    width: 634px;
    padding-bottom: 80px;
  }

  .recommend__swiper {
    width: 468px;
  }

  .recommend__item {
    width: 468px;
    min-height: 341px;
  }

  .recommend__item-wrapper {
    width: 136px;
  }

  .recommend__item.swiper-slide-active .recommend__item-wrapper {
    width: 468px;
  }

  .recommend__item.swiper-slide-prev .recommend__item-wrapper,
  .recommend__item.swiper-slide-duplicate-prev .recommend__item-wrapper {
    width: 468px;
  }

  .recommend_controls {
    width: 145px;
  }
}

@media screen and (max-width: 991.98px) {
  .recommend__title {
    margin-bottom: 30px;
  }

  .recommend__wrapper {
    flex-direction: column;
    justify-content: flex-start;
  }

  .recommend__info {
    width: 100%;
    margin-bottom: 40px;
  }

  .recommend__content {
    margin-bottom: 30px;
  }

  .recommend__swiper-wrapper {
    width: 100%;
    padding-bottom: 70px;
  }

  .recommend__swiper {
    width: 388px;
  }

  .recommend__item {
    width: 388px;
    min-height: 331px;
  }

  .recommend__item-wrapper {
    width: 184px;
  }

  .recommend__item-inner {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .recommend__name {
    margin-bottom: 15px;
  }

  .recommend__bnt {
    display: none;
  }

  .recommend__item.swiper-slide-active .recommend__bnt {
    display: block;
  }

  .recommend__item.swiper-slide-active .recommend__item-wrapper {
    width: 388px;
  }

  .recommend__item.swiper-slide-prev .recommend__item-wrapper,
  .recommend__item.swiper-slide-duplicate-prev .recommend__item-wrapper {
    width: 388px;
  }

  .recommend_controls {
    right: 50%;

    width: 110px;

    transform: translateX(50%);
  }
}

@media screen and (max-width: 767.98px) {
  .recommend__swiper {
    width: 360px;
  }

  .recommend__item {
    width: 360px;
    min-height: 315px;
  }

  .recommend__item-wrapper {
    width: 160px;
  }

  .recommend__item.swiper-slide-active .recommend__item-wrapper {
    width: 360px;
  }

  .recommend__item.swiper-slide-prev .recommend__item-wrapper,
  .recommend__item.swiper-slide-duplicate-prev .recommend__item-wrapper {
    width: 360px;
  }
}

@media screen and (max-width: 575.98px) {
  .recommend__title {
    margin-bottom: 25px;
  }

  .recommend__content {
    margin-bottom: 25px;
  }

  .recommend__swiper-wrapper {
    padding-bottom: 60px;
  }

  .recommend__swiper {
    width: 100%;
  }

  .recommend__item {
    width: 253px;
    min-height: 251px;
  }

  .recommend__item-wrapper {
    width: 253px;
  }

  .recommend__bnt {
    display: block;
  }

  .recommend__item.swiper-slide-active .recommend__item-wrapper {
    width: 253px;
  }

  .recommend__item.swiper-slide-prev .recommend__item-wrapper,
  .recommend__item.swiper-slide-duplicate-prev .recommend__item-wrapper {
    width: 253px;
  }
}
</style>
