<template>
  <button class="button">
    <slot />
  </button>
</template>

<script>
export default {
  name: "ButtonApp",
};
</script>

<style>
.button {
  position: relative;
  z-index: 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 275px;
  padding: 18px 52px;
  border: 1px solid #ffffff;

  font-family: 'Constantia', Tahoma, sans-serif;
  font-size: 17px;
  line-height: 1.5;
  vertical-align: middle;
  text-align: center;
  text-decoration: none;
  color: #ffffff;

  background-color: transparent;

  cursor: pointer;
  transition: background-color 0.4s ease-in;
}

.button::after {
  content: "";

  position: absolute;
  top: 5px;
  left: 5px;
  z-index: 1;

  width: 48px;
  height: 47px;

  background-image: url(@/assets/img/button.svg);
  background-size: contain;
  background-repeat: no-repeat;
}

.button::before {
  content: "";

  position: absolute;
  bottom: 5px;
  right: 5px;
  z-index: 1;

  width: 48px;
  height: 47px;

  background-image: url(@/assets/img/button.svg);
  background-size: contain;
  background-repeat: no-repeat;

  transform: rotate(180deg);
}

.button:hover {
  background-color: #735147;
}

.button_type2 {
  border-color: #735147;

  background-color: #735147;

  transition: background-color 0.4s ease-in, border-color 0.4s ease-in;
}

.button_type2:hover {
  background-color: transparent;
  border-color: #ffffff;

}

@media screen and (max-width: 1599.98px) {
  .button {
    min-width: 218px;
    padding: 13px 30px;

    font-size: 15px;
  }

  .button::after {
    content: "";

    top: 3px;
    left: 3px;

    width: 34px;
    height: 34px;
  }

  .button::before {
    bottom: 3px;
    right: 3px;

    width: 34px;
    height: 34px;
  }
}

@media screen and (max-width: 1199.98px) {
  .button {
    padding: 13px 38px;
    font-size: 14px;
  }
}

@media screen and (max-width: 991.98px) {
  .button {
    border-color: transparent;

    background-color: #735147;
    transition: none;
  }

  .button_type2:hover {
    border-color: #ffffff;

    background-color: #735147;
  }
}
</style>