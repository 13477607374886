<template>
  <section class="offer">
    <div class="container offer__container">
      <Title1 class="offer__title" v-html="getValue('title')" />
      <p class="offer__desc" v-html="getValue('subtitle')"></p>
      <Button class="offer__bnt button_type2" @click="popup">{{ getValue('btn.text') }}</Button>
    </div>
    <div class="offer__media">
      <picture class="offer__picture">
        <source :srcset="getValue('img.sizes.offer768')" media="(max-width: 991px)">
        <source :srcset="getValue('img.sizes.offer992')" media="(max-width:1199px)">
        <source :srcset="getValue('img.sizes.offer1200')" media="(max-width:1599px)">
        <source :srcset="getValue('img.sizes.offer1600')" media="(max-width:1919px)">
        <source :srcset="getValue('img.sizes.offer1920')" media="(min-width:1920px)">
        <img class="offer__img" width="1725" height="629" :src="getValue('img.url')" alt="">
      </picture>
    </div>
  </section>
</template>

<script>
import Title1 from "@/components/element/Title1App.vue";
import Button from "@/components/element/ButtonApp.vue";
import { getValue } from '@/helpers/index.js';

export default {
  name: 'OfferApp',
  components: {
    Title1,
    Button
  },
  props: {
    content: {
      required: true,
      default: () => ({}),
    },
  },
  computed: {
    getValue() {
      return (path) => {
        return getValue(this.content, path, "");
      }
    },
  },
  methods: {
    popup() {
      this.$store.commit('updatePopup', {
        status: 'booking',
        data: {
          popup: this.getValue('popup'),
          success: this.getValue('success'),
        }
      });
    }
  },
};
</script>

<style>
.offer {
  padding-top: 105px;
}

.offer__container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 80px !important;
}

.offer__title {
  width: 100%;
  padding-right: 441px;
  margin-bottom: 30px;

  text-transform: uppercase;
  overflow: hidden;

  transform: translateY(30px) skewY(3deg) scaleY(1.0);
  transform-origin: top left;
  transition: transform 1.5s cubic-bezier(0.16, 0.09, 0, 1.02) 1s, opacity 0.3s 1s;
  transition-property: transform, opacity;

  opacity: 0;
}

.start .offer__title {
  transform: translateY(0) skewY(0) scaleY(1);
  opacity: 1;
}

.offer__desc {
  width: 1089px;

  font-size: 18px;

  transform: translateY(30px) skewY(3deg) scaleY(1.0);
  transform-origin: top left;
  transition: transform 1.5s cubic-bezier(0.16, 0.09, 0, 1.02) 1.4s, opacity 0.3s 1.4s;
  transition-property: transform, opacity;

  opacity: 0;
}

.start .offer__desc {
  transform: translateY(0) skewY(0) scaleY(1);
  opacity: 1;
}

.offer__media {
  margin-left: calc((100% - var(--container)) / 2);
  overflow: hidden;
}

.offer__picture {
  display: block;
}

.offer__img {
  clip-path: polygon(100% 0, 100% 0, 100% 100%, 100% 100%);
  transform: scale(1.2);

  transition: clip-path 1.5s ease, transform 1s ease;
}

.start .offer__img {
  transform: scale(1);
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
}

.offer__img {
  display: block;
  width: 100%;
  height: auto;
  max-height: 976px;

  object-fit: cover;
  object-position: center;
}

.offer__bnt {
  transform: translateY(30px) skewY(3deg) scaleY(1.0);
  transform-origin: top left;
  transition: transform 1.5s cubic-bezier(0.16, 0.09, 0, 1.02) 1.6s, opacity 0.3s 1.6s;
  transition-property: transform, opacity;

  opacity: 0;
}

.start .offer__bnt {
  transform: translateY(0) skewY(0) scaleY(1);
  opacity: 1;
}

@media screen and (max-width: 1919.98px) {
  .offer {
    padding-top: 112px;
  }

  .offer__container {
    margin-bottom: 70px !important;
  }

  .offer__title {
    padding-right: 418px;
    margin-bottom: 22px;
  }

  .offer__desc {
    width: 980px;

    font-size: 16px;
  }

  .offer__img {
    height: 547px;
    max-height: none;
  }
}

@media screen and (max-width: 1599.98px) {
  .offer {
    padding-top: 93px;
  }

  .offer__container {
    margin-bottom: 50px !important;
  }

  .offer__title {
    padding-right: 257px;
    margin-bottom: 40px;
  }

  .offer__desc {
    width: 735px;

    font-size: 14px;
  }

  .offer__img {
    height: 415px;
  }
}

@media screen and (max-width: 1199.98px) {
  .offer {
    padding-top: 95px;
  }

  .offer__container {
    margin-bottom: 45px !important;
  }

  .offer__title {
    padding-right: 256px;
    margin-bottom: 33px;
  }

  .offer__desc {
    width: 633px;

    font-size: 12px;
  }

  .offer__img {
    height: 363px;
  }
}

@media screen and (max-width: 991.98px) {
  .offer {
    padding-top: 70px;
  }

  .offer__container {
    margin-bottom: 70px !important;
  }

  .offer__title {
    padding-right: 0;
    margin-bottom: 20px;
  }

  .offer__desc {
    width: 100%;
    margin-bottom: 25px;
  }

  .offer__img {
    height: 475px;
  }
}

@media screen and (max-width: 767.98px) {
  .offer {
    padding-top: 63px;
  }

  .offer__container {
    margin-bottom: 25px !important;
  }

  .offer__title {
    margin-bottom: 15px;
  }

  .offer__desc {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 575.98px) {
  .offer__media {
    margin-left: 15px;
  }

  .offer__img {
    height: auto;
  }
}
</style>
