<template>
  <header class="header" :class="{'open': status}">
    <div class="container header__container">
      <div class="header__nav" @click.self="closeMenu">
        <div class="header__nav-inner">
          <Menu class="header__menu" :content="menu" />
          <Social class="header__social" :content="social" />
        </div>
      </div>
      <Logo class="header__logo" />
      <div class="header__contacts">
        <a class="header__address" :href="getValue('address.link')" target="_blank">{{ getValue('address.text') }}</a>
        <a class="header__phone" :href="'tel:' + minificationPhone" target="_blank">{{ getValue('phone') }}</a>
        <Burger class="header__burger" @click="toggleMenu" />
      </div>
    </div>
  </header>
</template>

<script>
import Logo from "@/components/block/LogoApp.vue";
import Menu from "@/components/block/MainMenu.vue";
import Burger from "@/components/element/ButtonBurger.vue";
import Social from "@/components/block/SocialApp.vue";
import { getValue, getMinificationPhone } from '@/helpers/index.js';

export default {
  name: 'HeaderApp',
  components: {
    Menu,
    Logo,
    Burger,
    Social
  },
  props: {
    menu: {
      required: true,
      default: () => ([]),
    },
    content: {
      required: true,
      default: () => ({}),
    },
    social: {
      default: () => ({}),
    },
  },
  computed: {
    getValue() {
      return (path) => {
        return getValue(this.content, path, "");
      }
    },
    minificationPhone() {
      return getMinificationPhone(this.getValue('phone'));
    },
    status() {
      return this.$store.state.menu
    }
  },
  methods: {
    toggleMenu() {
      this.$store.commit('updateMenu');
    },
    closeMenu() {
      this.$store.commit('closeMenu');
    }
  }
};
</script>

<style>
.header {
  position: relative;
  z-index: 999;

  padding: 5px 0 11px 0;
  border-bottom: 1px solid #735147;

  opacity: 0;
  transition: opacity 2s ease;
}

.start .header {
  opacity: 1;
}

.header::after {
  content: "";

  position: absolute;
  bottom: 0;
  left: 50%;

  width: 47px;
  height: 26px;

  background-color: #0b1325;
  background-image: url(@/assets/img/header-icon.svg);
  background-repeat: no-repeat;
  background-size: contain;

  transform: translate(-50%, 50%);
}

.header__container {
  display: flex;
  align-items: center;
}

.header__nav {
  width: 650px;
}

.header__menu {
  width: 100%;
}

.header__social {
  display: none;
}

.header__logo {
  margin: 0 50px;
}

.header__contacts {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  width: 650px;
}

.header__address {
  margin-right: 60px;

  color: #ffffff;
  text-decoration: none;
  line-height: 1.5;

  transition: color 0.3s ease;
}

.header__address:hover {
  color: #735147;
}

.header__phone {
  color: #ffffff;
  text-decoration: none;
  line-height: 1.5;

  transition: color 0.3s ease;
}

.header__phone:hover {
  color: #735147;
}

.header__burger {
  display: none;
}

@media screen and (max-width: 1919.98px) {
  .header {
    padding: 5px 0 11px 0;
  }

  .header::after {
    width: 38px;
    height: 22px;
  }

  .header__nav {
    width: 595px;
  }

  .header__logo {
    margin: 0 46px;
  }

  .header__contacts {
    width: 595px;
  }
}

@media screen and (max-width: 1599.98px) {
  .header {
    padding: 5px 0 8px 0;
  }

  .header::after {
    width: 32px;
    height: 18px;
  }

  .header__nav {
    width: 462px;
  }

  .header__logo {
    margin: 0 27px;
  }

  .header__contacts {
    width: 462px;
  }

  .header__address {
    margin-right: 20px;
  }
}

@media screen and (max-width: 1199.98px) {
  .header {
    padding: 5px 0 3px 0;
  }

  .header__nav {
    width: 410px;
  }

  .header__logo {
    margin: 0 23px;
  }

  .header__contacts {
    width: 410px;
  }
}

@media screen and (max-width: 991.98px) {
  .header {
    padding: 4px 0 0 0;
  }

  .header__container {
    justify-content: space-between;
  }

  .header__nav {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 9;

    display: flex;
    justify-content: flex-end;
    width: 0%;
    height: 100vh;

    overflow: auto;

    transition: width 0s linear 0.5s;
  }

  .open .header__nav {
    width: 100%;
    transition: width 0s linear 0s;
  }

  .header__nav::before {
    content: "";

    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: -1;

    width: 0;
    height: 100%;

    background-color: rgba(0, 0, 0, .5);

    opacity: 0;
    transition: opacity 0.3s ease, width 0s linear 0.5s;
  }

  .open .header__nav::before {
    width: 100%;
    opacity: 1;

    transition: opacity 0.3s ease, width 0s linear 0s;
  }

  .header__nav-inner {
    display: flex;
    flex-direction: column;
    justify-content: center;

    width: 50%;
    min-height: 650px;
    padding: 100px 70px;

    background-color: #0b1325;

    clip-path: polygon(100% 0, 100% 0%, 100% 100%, 100% 100%);

    transition: clip-path 0.3s ease;
  }

  .open .header__nav-inner {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }

  .header__menu {
    margin-bottom: 90px;
  }

  .header__social {
    display: flex;
  }

  .header__logo {
    margin: 0;
  }

  .header__contacts {
    display: flex;
    z-index: 99;
    width: auto;
  }

  .header__address {
    display: none;
  }

  .header__phone {
    margin-right: 25px;
    transition: none;
  }

  .header__phone:hover {
    color: #ffffff;
  }

  .header__burger {
    display: block;
  }
}

@media screen and (max-width: 575.98px) {
  .header__nav-inner {
    width: 90%;
  }
}
</style>
