<template>
  <section class="booking">
    <div class="container">
      <Title2 class="booking__title" v-html="getValue('title')" />
      <div class="booking__wrapper">
        <div class="booking__media">
          <picture class="booking__picture">
            <source :srcset="getValue('img.sizes.booking1920')" media="(max-width: 768px)">
            <source :srcset="getValue('img.sizes.booking768')" media="(max-width: 991px)">
            <source :srcset="getValue('img.sizes.booking992')" media="(max-width:1199px)">
            <source :srcset="getValue('img.sizes.booking1200')" media="(max-width:1599px)">
            <source :srcset="getValue('img.sizes.booking1600')" media="(max-width:1919px)">
            <source :srcset="getValue('img.sizes.booking1920')" media="(min-width:1920px)">
            <img class="booking__img" width="600" height="443" :src="getValue('img.url')" alt=""
              data-aos="animation-img2">
          </picture>
        </div>
        <div class="booking__info">
          <p class="booking__desc" v-html="getValue('subtitle')"></p>
          <BookingForm class="booking__form" :content="content" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Title2 from "@/components/element/Title2App.vue";
import BookingForm from "@/components/block/BookingForm.vue";
import { getValue } from '@/helpers/index.js';

export default {
  name: 'BookingApp',
  components: {
    Title2,
    BookingForm
  },
  props: {
    content: {
      // Определяет данные для отображения
      required: true,
      default: () => ({}),
    },
  },
  computed: {
    getValue() {
      return (path) => {
        return getValue(this.content, path, "");
      }
    },
  },
};
</script>

<style>
.booking__title {
  margin-bottom: 50px;

  text-align: center;
  text-transform: uppercase;
}

.booking__wrapper {
  display: flex;
  align-items: center;
}

.booking__media {
  flex-shrink: 0;
  width: 600px;
  margin-right: 50px;
}

.booking__picture {
  display: block;
}

.booking__img {
  width: 100%;
  height: auto;

  object-fit: cover;
  object-position: center;
}

.booking__info {
  width: 752px;
}

.booking__desc {
  margin-bottom: 50px;
}

@media screen and (max-width: 1919.98px) {
  .booking__title {
    margin-bottom: 45px;
  }

  .booking__media {
    width: 545px;
  }

  .booking__info {
    width: 684px;
  }

  .booking__desc {
    margin-bottom: 45px;
  }
}

@media screen and (max-width: 1599.98px) {
  .booking__title {
    margin-bottom: 40px;
  }

  .booking__media {
    width: 406px;
  }

  .booking__info {
    width: 522px;
  }

  .booking__desc {
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 1199.98px) {
  .booking__title {
    margin-bottom: 35px;
  }

  .booking__media {
    width: 384px;
  }

  .booking__info {
    width: 551px;
  }

  .booking__desc {
    margin-bottom: 35px;
  }
}

@media screen and (max-width: 991.98px) {
  .booking__title {
    margin-bottom: 30px;
  }

  .booking__media {
    width: 286px;
    height: 493px;
    margin-right: 20px;
  }

  .booking__img {
    height: 100%;
  }

  .booking__info {
    width: 290px;
  }

  .booking__desc {
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 767.98px) {
  .booking__title {
    margin-bottom: 35px;
  }

  .booking__wrapper {
    flex-direction: column;
  }

  .booking__media {
    width: 100%;
    height: auto;
    margin-right: 0;
    margin-bottom: 25px;
  }

  .booking__img {
    height: auto;
  }

  .booking__info {
    width: 100%;
  }

  .booking__desc {
    margin-bottom: 25px;
  }
}
</style>
