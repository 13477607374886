import { createStore } from "vuex";
import axios from "axios";

export default createStore({
  state() {
    return {
      preloader: true,
      api: false,
      data: null,
      menu: false,
      popup: {
        status: null,
        data: null
      }
    };
  },
  mutations: {
    preloaderOff(state) {
      state.preloader = false;
    },
    statusApi(state) {
      state.api = true;
    },
    updateData(state, preload) {
      state.data = preload;
    },
    updateMenu(state) {
      state.menu = !state.menu;
    },
    closeMenu(state) {
      state.menu = false;
    },
    updatePopup(state, preload) {
      state.popup = preload;
    },
    updateClose(state) {
      state.popup = {
        status: null,
        data: null
      };
    },
  },
  actions: {
    async loadingData(context) {
      const [menuResponse, contentResponse] =
        await Promise.all([
          axios.get(`${window.siteApi}/wp-json/wp-api-menus/v2/menus/2`),
          axios.get(`${window.siteApi}/wp-json/wp/v2/pages/307`),
        ]);

      context.commit("updateData", {
        menuResponse: menuResponse.data.items,
        contentResponse: contentResponse.data.acf,
      });

      context.commit("statusApi");
      setTimeout(() => context.commit("preloaderOff"), 1000);
    },

    async submitForm(context, payload) {
      let bodyRequest = new FormData();

      for (let key in payload) {
        if (key != "id") {
          bodyRequest.append(key, payload[key]);
        }
      }

      axios({
        method: "post",
        url: `${window.siteApi}/wp-json/contact-form-7/v1/contact-forms/${payload.id}/feedback`,
        data: bodyRequest,
        headers: { "Content-Type": "multipart/form-data" },

      }).catch(function (error) {
        console.log(error);
      });
    },
  },
});
