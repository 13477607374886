<template>
  <button class="button-close" type="button" @click="$emit('click')">
    <svg class="button-close__icon" width="25" height="25" viewBox="0 0 25 25" fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd"
        d="M0.62501 0.625479C0.970182 0.280306 1.52982 0.280306 1.87499 0.625478L24.3746 23.1251C24.7198 23.4703 24.7198 24.0299 24.3746 24.3751C24.0295 24.7203 23.4698 24.7203 23.1247 24.3751L0.62501 1.87546C0.279838 1.53029 0.279837 0.970651 0.62501 0.625479Z"
        fill="#735147" />
      <path fill-rule="evenodd" clip-rule="evenodd"
        d="M0.62499 24.3745C0.279818 24.0293 0.279818 23.4697 0.62499 23.1245L23.1246 0.624873C23.4698 0.2797 24.0294 0.279701 24.3746 0.624873C24.7198 0.970046 24.7198 1.52968 24.3746 1.87485L1.87497 24.3745C1.5298 24.7197 0.970163 24.7197 0.62499 24.3745Z"
        fill="#735147" />
    </svg>
  </button>
</template>

<script>
export default {
  name: "ButtonClose",
  emits: ["click"],
};
</script>

<style>
.button-close {
  display: block;
  border: none;

  background-color: transparent;

  cursor: pointer;
}

.button-close__icon {
  display: block;
}

.button-close .button-close__icon path {
  transition: fill 0.4s ease;
}

.button-close:hover .button-close__icon path {
  fill: #9c6f62;
}
</style>
