<template>
  <component class="title-2" :is="tag">
    <slot />
  </component>
</template>

<script>
export default {
  name: "Title2App",
  props: {
    tag: {
      // Определяет возвращаемый тег, по умолчанию h2
      type: String,
      default: "h2",
    },
  },
};
</script>

<style>
.title-2 {
  font-family: 'Constantia', Tahoma, sans-serif;
  font-size: 74px;
  line-height: 1.5;
  color: #ffffff;
  font-weight: normal;
}

@media screen and (max-width: 1919.98px) {
  .title-2 {
    font-size: 68px;
  }
}

@media screen and (max-width: 1599.98px) {
  .title-2 {
    font-size: 54px;
  }
}

@media screen and (max-width: 1199.98px) {
  .title-2 {
    font-size: 48px;
  }
}

@media screen and (max-width: 991.98px) {
  .title-2 {
    font-size: 38px;
  }
}

@media screen and (max-width: 767.98px) {
  .title-2 {
    font-size: 24px;
  }
}
</style>
