<template>
  <button class="button-controls">
    <svg class="button-controls__icon" width="83" height="59" viewBox="0 0 83 59" fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_b_1786_11175)">
        <circle class="button-controls__circle" cx="53.5" cy="29.5" r="29" stroke="white" fill="#735147"
          fill-opacity="0" />
      </g>
      <rect y="29" width="59" height="1" rx="0.5" fill="white" />
      <path d="M46 22.2998C52.8 29.4998 62 29.4998 62 29.4998" stroke="white" stroke-linecap="round" />
      <path d="M46 36.6997C52.8 29.4997 62 29.4997 62 29.4997" stroke="white" stroke-linecap="round" />
      <circle cx="46" cy="37" r="1" fill="white" />
      <path
        d="M47.4004 22.1001C47.4004 22.6524 46.9527 23.1001 46.4004 23.1001C45.8481 23.1001 45.4004 22.6524 45.4004 22.1001C45.4004 21.5478 45.8481 21.1001 46.4004 21.1001C46.9527 21.1001 47.4004 21.5478 47.4004 22.1001Z"
        fill="white" />
      <defs>
        <filter id="filter0_b_1786_11175" x="14" y="-10" width="79" height="79" filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImage" stdDeviation="5" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_1786_11175" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_1786_11175" result="shape" />
        </filter>
      </defs>
    </svg>
  </button>
</template>

<script>
export default {
};
</script>

<style>
.button-controls {
  display: inline-block;
  padding: 0;
  border: none;
  margin: 0;

  background-color: transparent;
  cursor: pointer;
}

.button-controls__icon {
  display: block;
}

.button-controls__circle {
  transition: all 0.3s ease-in;
}

.button-controls:hover .button-controls__circle {
  fill-opacity: 1;
  stroke: #735147;
}

.button-controls_prev {
  transform: rotate(180deg);
}

@media screen and (max-width: 1919.98px) {
  .button-controls__icon {
    width: 73px;
    height: 52px;
  }
}

@media screen and (max-width: 1599.98px) {
  .button-controls__icon {
    width: 65px;
    height: 46px;
  }
}

@media screen and (max-width: 991.98px) {
  .button-controls__icon {
    width: 50px;
    height: 35px;
  }
}
</style>