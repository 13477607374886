<template>
  <section id="advantages">
    <div class="container">
      <ul class="advantages__list">
        <li class="advantages__item" v-for="item in getValue('list')" :key="item.title">
          <div class="advantages__icon">
            <img class="advantages__img" :src="item.img.url" alt="">
          </div>
          <Title4 class="advantages__title" v-html="item.title" />
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
import Title4 from "@/components/element/Title4App.vue";
import { getValue } from '@/helpers/index.js';

export default {
  name: "AdvantagesApp",
  components: {
    Title4,
  },
  props: {
    content: {
      // Определяет данные для отображения
      required: true,
      default: () => ({}),
    },
  },
  computed: {
    getValue() {
      return (path) => {
        return getValue(this.content, path, "");
      }
    },
  },
};
</script>

<style>
.advantages__list {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 30px;
  grid-row-gap: 30px;
}

.advantages__item {
  display: flex;
  align-items: center;
}

.advantages__icon {
  width: 95px;
  flex-shrink: 0;
}

.advantages__item:nth-child(1) .advantages__img {
  width: 53px;
  height: 60px;
}

.advantages__item:nth-child(2) .advantages__img {
  width: 65px;
  height: 41px;
}

.advantages__item:nth-child(3) .advantages__img {
  width: 65px;
  height: 56px;
}

.advantages__item:nth-child(4) .advantages__img {
  width: 41px;
  height: 60px;
}

@media screen and (max-width: 1919.98px) {
  .advantages__icon {
    width: 85px;
  }

  .advantages__item:nth-child(1) .advantages__img {
    width: 50px;
    height: 55px;
  }

  .advantages__item:nth-child(2) .advantages__img {
    width: 60px;
    height: 38px;
  }

  .advantages__item:nth-child(3) .advantages__img {
    width: 60px;
    height: 52px;
  }

  .advantages__item:nth-child(4) .advantages__img {
    width: 38px;
    height: 55px;
  }
}

@media screen and (max-width: 1599.98px) {
  .advantages__icon {
    width: 65px;
  }

  .advantages__item:nth-child(1) .advantages__img {
    width: 40px;
    height: 45px;
  }

  .advantages__item:nth-child(2) .advantages__img {
    width: 50px;
    height: 32px;
  }

  .advantages__item:nth-child(3) .advantages__img {
    width: 50px;
    height: 43px;
  }

  .advantages__item:nth-child(4) .advantages__img {
    width: 31px;
    height: 45px;
  }
}

@media screen and (max-width: 1199.98px) {
  .advantages__icon {
    width: 58px;
  }

  .advantages__item:nth-child(1) .advantages__img {
    width: 35px;
    height: 40px;
  }

  .advantages__item:nth-child(2) .advantages__img {
    width: 45px;
    height: 28px;
  }

  .advantages__item:nth-child(3) .advantages__img {
    width: 45px;
    height: 39px;
  }

  .advantages__item:nth-child(4) .advantages__img {
    width: 27px;
    height: 40px;
  }
}

@media screen and (max-width: 991.98px) {
  .advantages__list {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 2fr;
    grid-column-gap: 100px;
    grid-row-gap: 30px;
  }

  .advantages__icon {
    width: 58px;
  }

  .advantages__item:nth-child(1) .advantages__img {
    width: 35px;
    height: 40px;
  }

  .advantages__item:nth-child(2) .advantages__img {
    width: 45px;
    height: 28px;
  }

  .advantages__item:nth-child(3) .advantages__img {
    width: 45px;
    height: 39px;
  }

  .advantages__item:nth-child(1) .advantages__img {
    width: 27px;
    height: 40px;
  }
}

@media screen and (max-width: 575.98px) {
  .advantages__list {
    grid-column-gap: 15px;
  }

  .advantages__item {
    flex-direction: column;
    align-items: flex-start;
  }

  .advantages__icon {
    width: 58px;
    margin-bottom: 20px;
  }

  .advantages__item:nth-child(1) .advantages__img {
    width: 35px;
    height: 40px;
  }

  .advantages__item:nth-child(2) .advantages__img {
    width: 45px;
    height: 28px;
  }

  .advantages__item:nth-child(3) .advantages__img {
    width: 45px;
    height: 39px;
  }

  .advantages__item:nth-child(1) .advantages__img {
    width: 27px;
    height: 40px;
  }
}
</style>
