<template>
  <Header :menu="this.getValue('menuResponse')" :content="this.getValue('contentResponse.header')"
    :social="this.getValue('contentResponse.footer.social')" />
  <main>
    <Offer class="offer-page" :content="this.getValue('contentResponse.offer')" />
    <About class="about-page" :content="this.getValue('contentResponse.about')" />
    <Advantages class="advantages-page" :content="this.getValue('contentResponse.advantages')" />
    <Gallery class="gallery-page" :content="this.getValue('contentResponse.slider_foto')" />
    <Recommend class="recommend-page" :content="this.getValue('contentResponse.product_list')" />
    <Reviews class="reviews-page" :content="this.getValue('contentResponse.reviews')" />
    <News class="news-page" :content="this.getValue('contentResponse.news')" />
    <Booking class="booking-page" :content="this.getValue('contentResponse.booking')" />
    <Contacts class="сontacts-page" :content="this.getValue('contentResponse.contacts')" />
  </main>
  <Footer class="footer-page" :menu="this.getValue('menuResponse')"
    :content="this.getValue('contentResponse.footer')" />
  <PopupIndex />
</template>

<script>
import Header from "@/components/section/HeaderApp.vue";
import Offer from "@/components/section/OfferApp.vue";
import About from "@/components/section/AboutApp.vue";
import Advantages from "@/components/section/AdvantagesApp.vue";
import Gallery from "@/components/section/GalleryApp.vue";
import Recommend from "@/components/section/RecommendApp.vue";
import Reviews from "@/components/section/ReviewsApp.vue";
import News from "@/components/section/NewsApp.vue";
import Booking from "@/components/section/BookingApp.vue";
import Contacts from "@/components/section/ContactsApp.vue";
import Footer from "@/components/section/FooterApp.vue";
import PopupIndex from "@/components/section/popup/PopupIndex.vue";
import { getValue } from '@/helpers/index.js';
import AOS from 'aos';

export default {
  components: {
    Header,
    Offer,
    About,
    Advantages,
    Gallery,
    Recommend,
    Reviews,
    News,
    Booking,
    Contacts,
    Footer,
    PopupIndex
  },
  computed: {
    getValue() {
      return (path) => {
        return getValue(this.$store.state.data, path, "");
      }
    },
  },
  mounted() {
    AOS.init();

    setTimeout(() => document.body.classList.add('start'), 800);
  }
};
</script>

<style>
.offer-page {
  margin-bottom: 150px;
}

.about-page {
  margin-bottom: 150px;
}

.advantages-page {
  margin-bottom: 150px;
}

.gallery-page {
  margin-bottom: 150px;
}

.recommend-page {
  margin-bottom: 150px;
}

.reviews-page {
  margin-bottom: 150px;
}

.news-page {
  margin-bottom: 150px;
}

.booking-page {
  margin-bottom: 150px;
}

.сontacts-page {
  margin-bottom: 150px;
}

@media screen and (max-width: 1919.98px) {
  .offer-page {
    margin-bottom: 130px;
  }

  .about-page {
    margin-bottom: 130px;
  }

  .advantages-page {
    margin-bottom: 130px;
  }

  .gallery-page {
    margin-bottom: 130px;
  }

  .recommend-page {
    margin-bottom: 130px;
  }

  .reviews-page {
    margin-bottom: 130px;
  }

  .news-page {
    margin-bottom: 130px;
  }

  .booking-page {
    margin-bottom: 130px;
  }

  .сontacts-page {
    margin-bottom: 130px;
  }
}

@media screen and (max-width: 1599.98px) {
  .offer-page {
    margin-bottom: 100px;
  }

  .about-page {
    margin-bottom: 100px;
  }

  .advantages-page {
    margin-bottom: 100px;
  }

  .gallery-page {
    margin-bottom: 100px;
  }

  .recommend-page {
    margin-bottom: 100px;
  }

  .reviews-page {
    margin-bottom: 100px;
  }

  .news-page {
    margin-bottom: 100px;
  }

  .booking-page {
    margin-bottom: 100px;
  }

  .сontacts-page {
    margin-bottom: 100px;
  }
}

@media screen and (max-width: 1199.98px) {
  .offer-page {
    margin-bottom: 80px;
  }

  .about-page {
    margin-bottom: 80px;
  }

  .advantages-page {
    margin-bottom: 80px;
  }

  .gallery-page {
    margin-bottom: 80px;
  }

  .recommend-page {
    margin-bottom: 80px;
  }

  .reviews-page {
    margin-bottom: 80px;
  }

  .news-page {
    margin-bottom: 80px;
  }

  .booking-page {
    margin-bottom: 80px;
  }

  .сontacts-page {
    margin-bottom: 80px;
  }
}

@media screen and (max-width: 991.98px) {
  .offer-page {
    margin-bottom: 66px;
  }

  .about-page {
    margin-bottom: 70px;
  }

  .advantages-page {
    margin-bottom: 70px;
  }

  .gallery-page {
    margin-bottom: 70px;
  }

  .recommend-page {
    margin-bottom: 70px;
  }

  .reviews-page {
    margin-bottom: 70px;
  }

  .news-page {
    margin-bottom: 70px;
  }

  .booking-page {
    margin-bottom: 70px;
  }

  .сontacts-page {
    margin-bottom: 70px;
  }
}

@media screen and (max-width: 767.98px) {
  .offer-page {
    margin-bottom: 60px;
  }

  .about-page {
    margin-bottom: 60px;
  }

  .advantages-page {
    margin-bottom: 60px;
  }

  .gallery-page {
    margin-bottom: 60px;
  }

  .recommend-page {
    margin-bottom: 60px;
  }

  .reviews-page {
    margin-bottom: 60px;
  }

  .news-page {
    margin-bottom: 60px;
  }

  .booking-page {
    margin-bottom: 60px;
  }

  .сontacts-page {
    margin-bottom: 60px;
  }
}
</style>
