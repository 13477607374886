<template>
  <ul class="social">
    <li class="social__item" v-for="item in content" :key="item.icon">
      <a class="social__link" :href="item.link" target="_blank">{{ item.icon }}</a>
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    content: {
      required: true,
      default: () => ({}),
    },
  }
};
</script>
<style>
.social {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 156px;
  margin-bottom: -22px;
  margin-right: -22px;
}

.social__item {
  margin: 0 22px 22px 0;
}

.social__link {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 30px;
  min-height: 30px;
  border: 1px solid #735147;

  font-family: 'Constantia', Tahoma, sans-serif;
  font-size: 14px;
  line-height: 1.7;
  color: #ffffff;
  text-decoration: none;

  transition: background-color 0.3s ease;
}

.social__link:hover {
  background-color: #735147;
}

@media screen and (max-width: 1599.98px) {
  .social {
    width: 156px;
    margin-bottom: -20px;
    margin-right: -20px;
  }

  .social__item {
    margin: 0 20px 20px 0;
  }

  .social__link {
    min-width: 28px;
    min-height: 28px;

    font-size: 13px;
  }
}
</style>
