<template>
  <Preloader v-if="preloader" />
  <IndexPage v-if="api" />
</template>

<script>
import Preloader from "@/components/section/PreloaderApp.vue";
import IndexPage from "@/views/IndexPage.vue";

export default {
  name: "App",
  components: {
    Preloader,
    IndexPage,
  },
  computed: {
    preloader() {
      return this.$store.state.preloader;
    },
    api() {
      return this.$store.state.api;
    }
  },
  mounted() {
    this.$store.dispatch("loadingData");
  },
};
</script>

<style>

</style>
