<template>
  <div class="popup-booking">
    <ButtonClose class="popup-booking__close" @click="close" />
    <div class="popup-booking__media">
      <img class="popup-booking__img" src="@/assets/img/popup_img.jpg" width="416" height="697" alt="">
    </div>
    <div class="popup-booking__info">
      <Title3 class="popup-booking__title" v-html="getValue('popup.title')" />
      <p class="popup-booking__desc" v-html="getValue('popup.subtitle')"></p>
      <BookingForm class="popup-booking__form"
        :content="{bnt: getValue('popup.btn.text'), success: getValue('success') }" />
    </div>
  </div>
</template>

<script>
import ButtonClose from "@/components/element/ButtonClose.vue";
import Title3 from "@/components/element/Title3App.vue";
import BookingForm from "@/components/block/BookingForm.vue";
import { getValue } from '@/helpers/index.js';

export default {
  name: 'PopupBooking',
  components: {
    ButtonClose,
    Title3,
    BookingForm
  },
  props: {
    content: {
      required: true,
      default: () => ({}),
    },
  },
  computed: {
    getValue() {
      return (path) => {
        return getValue(this.content, path, "");
      }
    },
  },
  methods: {
    close() {
      this.$store.commit('updateClose');
    }
  },
};
</script>

<style>
.popup-booking {
  position: relative;

  display: flex;
  width: 1298px;

  background-color: #0c1427;
}

.popup-booking__close {
  position: absolute;
  top: 30px;
  right: 30px;
}

.popup-booking__media {
  flex-shrink: 0;
  width: 416px;
}

.popup-booking__img {
  height: 100%;

  object-fit: cover;
  object-position: center;
}

.popup-booking__info {
  padding: 110px 80px 110px 50px;
}

.popup-booking__title {
  margin-bottom: 50px;
  text-transform: uppercase;
}

.popup-booking__desc {
  margin-bottom: 50px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

@media screen and (max-width: 1919.98px) {
  .popup-booking {
    width: 1180px;
  }

  .popup-booking__close {
    top: 20px;
    right: 20px;
  }

  .popup-booking__media {
    width: 390px;
  }

  .popup-booking__info {
    padding: 100px 60px 80px 40px;
  }

  .popup-booking__title {
    margin-bottom: 45px;
  }

  .popup-booking__desc {
    margin-bottom: 45px;
  }
}

@media screen and (max-width: 1599.98px) {
  .popup-booking {
    width: 1080px;
  }

  .popup-booking__media {
    width: 360px;
  }

  .popup-booking__title {
    margin-bottom: 40px;
  }

  .popup-booking__desc {
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 1199.98px) {
  .popup-booking {
    width: 940px;
  }

  .popup-booking__media {
    width: 340px;
  }

  .popup-booking__info {
    padding: 100px 60px 80px 40px;
  }

  .popup-booking__title {
    margin-bottom: 40px;
  }

  .popup-booking__desc {
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 991.98px) {
  .popup-booking {
    width: 720px;
  }

  .popup-booking__media {
    width: 340px;
  }

  .popup-booking__info {
    padding: 70px 40px 40px 30px;
  }

  .popup-booking__title {
    margin-bottom: 30px;
  }

  .popup-booking__desc {
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 767.98px) {
  .popup-booking {
    align-items: center;
    width: 100%;
    height: 100%;
  }

  .popup-booking__media {
    display: none;
  }

  .popup-booking__info {
    padding: 100px 100px 60px 100px;
  }

  .popup-booking__title {
    margin-bottom: 30px;
  }

  .popup-booking__desc {
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 575.98px) {
  .popup-booking {
    width: 100%;
    height: 100%;

  }

  .popup-booking__info {
    padding: 60px 25px 60px 25px;
  }
}
</style>
