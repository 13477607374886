<template>
  <section class="gallery">
    <div class="container-gallery">
      <div class="gallery__wrapper swiper">
        <ul class="gallery__list swiper-wrapper">
          <li class="gallery__item swiper-slide" v-for="item in getValue('foto')" :key="item.id">
            <picture class="gallery__picture">
              <source :srcset="item.sizes.gallery768" media="(max-width: 991px)">
              <source :srcset="item.sizes.gallery992" media="(max-width:1199px)">
              <source :srcset="item.sizes.gallery1200" media="(max-width:1599px)">
              <source :srcset="item.sizes.gallery1600" media="(max-width:1919px)">
              <source :srcset="item.sizes.gallery1920" media="(min-width:1920px)">
              <img class="gallery__img" width="1209" height="704" :src="item.url" alt="" data-aos="animation-img">
            </picture>
          </li>
        </ul>
      </div>
      <div class="gallery__controls">
        <ButtonControls class="gallery__controls-prev button-controls_prev" />
        <ButtonControls class="gallery__controls-next" />
      </div>
    </div>
  </section>
</template>

<script>
import Swiper, { Navigation } from "swiper";
import { getValue } from '@/helpers/index.js';
import ButtonControls from "@/components/element/ButtonControls.vue";
import "swiper/css";
import "swiper/css/pagination";

export default {
  name: "GalleryApp",
  components: {
    ButtonControls
  },
  props: {
    content: {
      // Определяет данные для отображения
      required: true,
      default: () => ({}),
    },
  },
  computed: {
    getValue() {
      return (path) => {
        return getValue(this.content, path, "");
      }
    },
  },
  mounted() {
    new Swiper(".gallery__wrapper", {
      modules: [Navigation],
      slidesPerView: "auto",
      centeredSlides: true,
      speed: 500,
      navigation: {
        nextEl: ".gallery__controls-next",
        prevEl: ".gallery__controls-prev",
      },
      breakpoints: {
        320: {
          spaceBetween: 15,
        },
        767: {
          spaceBetween: 20,
        },
        992: {
          spaceBetween: 30,
        },
      },
    });
  },
};
</script>

<style>
.gallery {
  overflow: hidden;
}

.container-gallery {
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
}

.gallery__wrapper {
  width: 490px;
  margin-left: 0;
  margin-bottom: 50px;

  overflow: visible;
}

.gallery__item {
  width: 490px;
  height: 704px;

  transition: width 0.8s ease;
}

.gallery__img {
  width: 100%;
  height: 100%;

  object-fit: cover;
  object-position: left center;
}

.gallery__item.swiper-slide-active {
  width: 1209px;
  height: 704px;
  padding-left: 0;
}

.gallery__controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 196px;
  margin: 0 auto;
}

@media screen and (max-width: 1919.98px) {
  .gallery__wrapper {
    width: 446px;
    margin-bottom: 45px;
  }

  .gallery__item {
    width: 446px;
    height: 594px;
  }

  .gallery__item.swiper-slide-active {
    width: 1023px;
    height: 594px;
  }

  .gallery__controls {
    width: 166px;
  }
}

@media screen and (max-width: 1599.98px) {
  .container-gallery {
    max-width: var(--container);
  }

  .gallery__wrapper {
    width: 338px;
    margin-bottom: 40px;
  }

  .gallery__item {
    width: 338px;
    height: 410px;
  }

  .gallery__item.swiper-slide-active {
    width: 706px;
    height: 410px;
  }

  .gallery__controls {
    width: 150px;
  }
}

@media screen and (max-width: 1199.98px) {
  .gallery__wrapper {
    width: 338px;
    margin-bottom: 35px;
  }

  .gallery__item {
    width: 338px;
    height: 416px;
  }

  .gallery__item.swiper-slide-active {
    width: 717px;
    height: 416px;
  }
}

@media screen and (max-width: 991.98px) {
  .gallery__wrapper {
    width: 212px;
    margin-bottom: 30px;
  }

  .gallery__item {
    width: 212px;
    height: 260px;
  }

  .gallery__item.swiper-slide-active {
    width: 450px;
    height: 260px;
  }

  .gallery__controls {
    width: 110px;
  }
}

@media screen and (max-width: 575.98px) {
  .gallery__wrapper {
    width: 119px;
    margin-bottom: 30px;
  }

  .gallery__item {
    width: 119px;
    height: 146px;
  }

  .gallery__item.swiper-slide-active {
    width: 253px;
    height: 146px;
  }
}
</style>
