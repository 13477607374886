<template>
  <div class="popup-success">
    <Title3 class="popup-success__title" v-html="getValue('title')" />
    <p class="popup-success__desc" v-html="getValue('subtitle')"></p>
  </div>
</template>

<script>
import Title3 from "@/components/element/Title3App.vue";
import { getValue } from '@/helpers/index.js';

export default {
  name: 'PopupSuccess',
  components: {
    Title3,
  },
  props: {
    content: {
      required: true,
      default: () => ({}),
    },
  },
  computed: {
    getValue() {
      return (path) => {
        return getValue(this.content, path, "");
      }
    },
  },
};
</script>

<style>
.popup-success {
  position: relative;

  width: 700px;
  padding: 110px 81px;

  background-color: #0c1427;
}

.popup-success::before {
  content: "";

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;

  width: 100%;
  height: 100%;
  border: 65px solid;
  border-image-source: url("@/assets/img/map-border.png");
  border-image-slice: 65;
  border-image-width: 65px;

  pointer-events: none;
}

.popup-success__title {
  margin-bottom: 45px;

  text-transform: uppercase;
}

.popup-success__desc {
  width: 450px;
}

@media screen and (max-width: 1919.98px) {
  .popup-success {
    width: 680px;
    padding: 100px 71px;

    background-color: #0c1427;
  }

  .popup-success::before {
    border-image-width: 60px;
  }

  .popup-success__title {
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 1599.98px) {
  .popup-success {
    width: 640px;
  }

  .popup-success__title {
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 1199.98px) {
  .popup-success {
    width: 600px;
  }

  .popup-success__title {
    margin-bottom: 25px;
  }

  .popup-success__desc {
    width: 400px;
  }
}

@media screen and (max-width: 991.98px) {
  .popup-success {
    width: 500px;
  }

  .popup-success__title {
    margin-bottom: 25px;
  }
}

@media screen and (max-width: 767.98px) {
  .popup-success {
    width: 500px;
    padding: 90px 61px;
  }

  .popup-success::before {
    border-image-width: 50px;
  }

  .popup-success__desc {
    width: 400px;
  }
}

@media screen and (max-width: 575.98px) {
  .popup-success {
    width: 90%;
    padding: 60px 45px;
  }

  .popup-success::before {
    border-image-width: 45px;
  }

  .popup-success__title {
    margin-bottom: 15px;
  }

  .popup-success__desc {
    width: 100%;
  }
}
</style>
