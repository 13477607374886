<template>
  <div class="popup" v-if="getStatus" @click.self="close">
    <PopupBooking v-if="getStatus === 'booking'" :content="getData" />
    <PopupСallback v-else-if="getStatus === 'сallback'" :content="getData" />
    <PopupFeedback v-else-if="getStatus === 'feedback'" :content="getData" />
    <PopupSuccess v-else-if="getStatus === 'success'" :content="getData" />
  </div>
</template>

<script>
import PopupBooking from "@/components/section/popup/PopupBooking.vue";
import PopupСallback from "@/components/section/popup/PopupСallback.vue";
import PopupSuccess from "@/components/section/popup/PopupSuccess.vue";
import PopupFeedback from "@/components/section/popup/PopupFeedback.vue";

export default {
  name: 'PopupIndex',
  components: {
    PopupBooking,
    PopupСallback,
    PopupSuccess,
    PopupFeedback
  },
  computed: {
    getStatus() {
      return this.$store.state.popup.status;
    },
    getData() {
      return this.$store.state.popup.data;
    },
  },
  methods: {
    close() {
      this.$store.commit('updateClose');
    }
  },
};
</script>

<style>
.popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: rgba(0, 0, 0, 0.5);
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  transform: translate(-100%);
}
</style>
