<template>
  <li class="item-news">
    <div class="item-news__media">
      <picture class="item-news__picture">
        <source :srcset="getValue('img.sizes.news768')" media="(max-width: 991px)">
        <source :srcset="getValue('img.sizes.news992')" media="(max-width:1199px)">
        <source :srcset="getValue('img.sizes.news1200')" media="(max-width:1599px)">
        <source :srcset="getValue('img.sizes.news1600')" media="(max-width:1919px)">
        <source :srcset="getValue('img.sizes.news1920')" media="(min-width:1920px)">
        <img class="item-news__img" width="490" height="443" :src="getValue('img.url')" alt="">
      </picture>
    </div>
    <Title4 class="item-news__name" v-html="getValue('name')" />
    <div class="item-news__content content" v-html="getValue('text')"></div>
    <Title4 class="item-reviews__date" tag="time" v-html="getValue('date')"></Title4>
  </li>
</template>

<script>
import Title4 from "@/components/element/Title4App.vue";
import { getValue } from '@/helpers/index.js';

export default {
  name: 'ItemReviews',
  components: {
    Title4
  },
  props: {
    content: {
      // Определяет данные для отображения
      required: true,
      default: () => ({}),
    },
  },
  computed: {
    getValue() {
      return (path) => {
        return getValue(this.content, path, "");
      }
    },
  },
};
</script>

<style>
.item-news {
  height: auto;
  display: flex;
  flex-direction: column;
}

.item-news__media {
  margin-bottom: 30px;
}

.item-news__name {
  margin-bottom: 25px;
}

.item-news__content {
  margin-bottom: 25px;
}

.item-reviews__date {
  margin-top: auto;
}

@media screen and (max-width: 1919.98px) {
  .item-news__media {
    margin-bottom: 25px;
  }

  .item-news__img {
    width: 446px;
    height: 403px;
  }

  .item-news__name {
    margin-bottom: 20px;
  }

  .item-news__content {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 1599.98px) {
  .item-news__media {
    margin-bottom: 20px;
  }

  .item-news__img {
    width: 339px;
    height: 307px;
  }

  .item-news__name {
    margin-bottom: 15px;
  }

  .item-news__content {
    margin-bottom: 15px;
  }
}

@media screen and (max-width: 1199.98px) {
  .item-news__media {
    margin-bottom: 15px;
  }

  .item-news__img {
    width: 302px;
    height: 273px;
  }
}

@media screen and (max-width: 991.98px) {
  .item-news__img {
    width: 286px;
    height: 231px;
  }
}

@media screen and (max-width: 767.98px) {
  .item-news__img {
    width: 100%;
    height: auto;
  }

  .item-news__name {
    margin-bottom: 10px;
  }

  .item-news__content {
    margin-bottom: 10px;
  }
}
</style>
