export function getMinificationPhone(value = {}) {
  return value.replace(/[^0-9]/g, '');
}

export function formatCurrencies(value = 0) {
  const formatter = new Intl.NumberFormat('ru-Ru', {
    style: 'currency',
    currency: 'RUB',
    currencyDisplay: "code"
  });

  return formatter.format(value).replace("RUB", "");
}

export function getValue(object = {}, path = "", defaultValue) {
  if (typeof (object) !== 'object' || object === null) {
    console.log('Первый аргумент должен быть объект');
    return defaultValue;
  }

  if (typeof (path) !== 'string') {
    console.log('Второй аргумент должен быть строкой');
    return defaultValue;
  }

  let data = object;
  return path.split('.').every(step => ((data = data[step]) !== undefined)) ? data : "";
}

export function validation(value, type) {
  if (value == '') return false;

  if (type == 'phone') {
    let pattern = /^\+?(\d{1,3})?[- .]?\(?(?:\d{2,3})\)?[- .]?\d\d\d[- .]?\d\d\d\d$/;

    return pattern.test(value.replace(/[^0-9]/g, ''));
  }

  if (type == 'email') {
    let pattern = /^[A-Z0-9._%+-]+@[A-Z0-9-]+.+.[A-Z]{2,4}$/i;

    return pattern.test(value);
  }

  return true;
}