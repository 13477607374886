<template>
  <ul class="main-menu">
    <li class="main-menu__item" v-for="item in content" :key="item.id">
      <a class="main-menu__link" href="#" v-scroll-to="item.url">
        {{ item.title }}
      </a>
    </li>
  </ul>
</template>

<script>
export default {
  name: "MainMenu",
  props: {
    content: {
      // Определяет данные для отображения
      required: true,
      default: () => ({}),
    },
  },
};
</script>

<style>
.main-menu {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.main-menu__item {
  margin-right: 60px;
}

.main-menu__item:last-child {
  margin-right: 0;
}

.main-menu__link {
  position: relative;

  display: block;
  padding: 3px 0;

  line-height: 1.5;
  color: #ffffff;
  text-decoration: none;
}

.main-menu_type2 .main-menu__link {
  color: #858993;
}

.main-menu__link::after {
  content: "";

  position: absolute;
  left: 50%;
  bottom: -15px;

  width: 67px;
  height: 13px;

  background-image: url(@/assets/img/menu-icon.svg);
  background-repeat: no-repeat;

  transform: translate(-50%);
  opacity: 0;

  transition: opacity 0.3s ease-in;
}

.main-menu__link:hover::after {
  opacity: 1;
}

@media screen and (max-width: 1919.98px) {
  .main-menu__item {
    margin-right: 50px;
  }
}

@media screen and (max-width: 1599.98px) {
  .main-menu__item {
    margin-right: 40px;
  }
}

@media screen and (max-width: 1199.98px) {
  .main-menu__item {
    margin-right: 30px;
  }
}

@media screen and (max-width: 991.98px) {
  .main-menu {
    flex-direction: column;
    align-items: flex-start;
  }

  .main-menu__item {
    margin-right: 0px;
    margin-bottom: 24px;
  }

  .main-menu__item:last-child {
    margin-bottom: 0;
  }

  .main-menu__link {
    padding: 0;

    font-family: 'Constantia', Tahoma, sans-serif;
    font-size: 24px;
  }

  .main-menu__link::after {
    display: none;
  }
}
</style>
