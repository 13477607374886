<template>
  <section class="news" data-aos="animation-news" id="news">
    <div class="container">
      <Title2 class="news__title" v-html="getValue('title')" />
      <div class="news__wrapper swiper">
        <ul class="news__list swiper-wrapper">
          <ItemNews class="news__item swiper-slide" v-for="item in getValue('list')" :key="item.name" :content="item" />
        </ul>
      </div>
      <div class="news_controls">
        <ButtonControls class="news_controls-prev button-controls_prev" />
        <ButtonControls class="news_controls-next" />
      </div>
    </div>
  </section>
</template>

<script>
import Title2 from "@/components/element/Title2App.vue";
import ItemNews from "@/components/block/item/ItemNews.vue";
import ButtonControls from "@/components/element/ButtonControls.vue";
import { getValue } from '@/helpers/index.js';
import Swiper, { Navigation } from "swiper";
import "swiper/css";

export default {
  name: 'NewsApp',
  components: {
    Title2,
    ItemNews,
    ButtonControls
  },
  props: {
    content: {
      // Определяет данные для отображения
      required: true,
      default: () => ({}),
    },
  },
  computed: {
    getValue() {
      return (path) => {
        return getValue(this.content, path, "");
      }
    },
  },
  mounted() {
    new Swiper(".news__wrapper", {
      modules: [Navigation],
      speed: 500,
      navigation: {
        nextEl: ".news_controls-next",
        prevEl: ".news_controls-prev",
      },
      pagination: {
        el: ".gallery__pagination",
        type: "bullets",
      },
      breakpoints: {
        320: {
          spaceBetween: 15,
          slidesPerView: "auto",
        },
        525: {
          spaceBetween: 20,
          slidesPerView: 2,
        },
        992: {
          spaceBetween: 30,
          slidesPerView: 3,
        },
      },
    });
  },
};
</script>

<style>
.news {
  overflow: hidden;
}

.news__title {
  margin-bottom: 50px;

  text-transform: uppercase;
}

.news__wrapper {
  margin-bottom: 50px;

  overflow: visible;
}

.news__list {
  justify-content: stretch;
}

.news_controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 196px;
  margin: 0 auto;
}

[data-aos^=animation-news] {
  transform: translateX(100%);

  transition: transform 1s ease;
}

[data-aos^=animation-news].aos-animate {
  transform: translateX(0);
}

@media screen and (max-width: 1919.98px) {
  .news__title {
    margin-bottom: 45px;
  }

  .news__wrapper {
    margin-bottom: 45px;
  }

  .news_controls {
    width: 166px;
  }
}

@media screen and (max-width: 1599.98px) {
  .news__title {
    margin-bottom: 40px;
  }

  .news__wrapper {
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 1199.98px) {
  .news__title {
    margin-bottom: 35px;
  }

  .news__wrapper {
    margin-bottom: 35px;
  }

  .news_controls {
    width: 145px;
  }
}

@media screen and (max-width: 991.98px) {
  .news__title {
    margin-bottom: 30px;
  }

  .news__wrapper {
    margin-bottom: 30px;
  }

  .news_controls {
    width: 110px;
  }
}

@media screen and (max-width: 767.98px) {
  .news__title {
    margin-bottom: 25px;
  }

  .news__wrapper {
    margin-bottom: 25px;
  }
}

@media screen and (max-width: 575.98px) {
  .news__item {
    width: 253px;
  }
}
</style>
