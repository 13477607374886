<template>
  <component class="title-3" :is="tag">
    <slot />
  </component>
</template>

<script>
export default {
  name: "Title3App",
  props: {
    tag: {
      // Определяет возвращаемый тег, по умолчанию h3
      type: String,
      default: "h3",
    },
  },
};
</script>

<style>
.title-3 {
  font-family: 'Constantia', Tahoma, sans-serif;
  font-size: 50px;
  line-height: 1.3;
  color: #ffffff;
  font-weight: normal;
}

@media screen and (max-width: 1919.98px) {
  .title-3 {
    font-size: 48px;
  }
}

@media screen and (max-width: 1599.98px) {
  .title-3 {
    font-size: 42px;
  }
}

@media screen and (max-width: 1199.98px) {
  .title-3 {
    font-size: 38px;
  }
}

@media screen and (max-width: 991.98px) {
  .title-3 {
    font-size: 32px;
  }
}

@media screen and (max-width: 767.98px) {
  .title-3 {
    font-size: 22px;
  }
}
</style>
