<template>
  <footer class="footer">
    <div class="container">
      <div class="footer__top">
        <Menu class="footer__menu main-menu_type2" :content="menu" />
        <Logo class="footer__logo" />
        <div class="footer__contacts">
          <div class="footer__phone" v-html="getPhone"></div>
          <Social class="footer__social" :content="getValue('social')" />
        </div>
      </div>
      <div class="footer__bottom">
        <p class="footer__сopyright" v-html="getValue('sopyright')"></p>
        <p class="footer__dev">Разработано в <a class="footer__dev-link" href="https://stebnev-studio.ru/"
            target="_blank">Stebnev Studio</a></p>
      </div>
    </div>
  </footer>
</template>

<script>
import Logo from "@/components/block/LogoApp.vue";
import Menu from "@/components/block/MainMenu.vue";
import Social from "@/components/block/SocialApp.vue";
import { getValue, getMinificationPhone } from '@/helpers/index.js';

export default {
  name: 'HeaderApp',
  components: {
    Menu,
    Logo,
    Social
  },
  props: {
    menu: {
      required: true,
      default: () => ([]),
    },
    content: {
      required: true,
      default: () => ({}),
    },
    social: {
      default: () => ({}),
    },
  },
  computed: {
    getValue() {
      return (path) => {
        return getValue(this.content, path, "");
      }
    },
    getPhone() {
      let phone = this.getValue('phone_list');
      let value = "";

      if (phone.length) {
        phone.forEach((item) => {
          let formatPhone = getMinificationPhone(item.phone);
          value += '<a href="tel:' + formatPhone + '" target="_blank">' + item.phone + '</a>';
        });
      }

      return value;
    },
  },
};
</script>

<style>
.footer {
  position: relative;
  z-index: 0;

  padding-top: 33px;
  padding-bottom: 40px;

  border-top: 1px solid #735147;
}

.footer::after {
  content: "";

  position: absolute;
  top: 0;
  left: 50%;

  width: 47px;
  height: 26px;

  background-color: #0b1325;
  background-image: url(@/assets/img/header-icon.svg);
  background-repeat: no-repeat;
  background-size: contain;

  transform: translate(-50%, -50%);
}

.footer__top {
  display: flex;
  align-items: center;
  margin-bottom: 23px;
}

.footer__menu {
  width: 650px;
}

.footer__logo {
  margin: 0 50px 0 50px;
}

.footer__contacts {
  display: flex;
  align-items: center;
  width: 650px;
  padding-left: 70px;
}

.footer__phone {
  margin-right: auto;
}

.footer__phone a {
  color: #858993;
  text-decoration: none;
  line-height: 1.5;

  transition: color 0.3s ease;
}

.footer__phone a:hover {
  color: #735147;
}

.footer__phone a::after {
  content: ", "
}

.footer__phone a:last-child::after {
  display: none;
}

.footer__bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer__сopyright {
  font-size: 12px;
  line-height: 1.5;
  color: #858993;
}

.footer__dev {
  font-size: 12px;
  line-height: 1.5;
  color: #858993;
}

.footer__dev-link {
  position: relative;

  color: #858993;

  text-decoration: none;

  transition: color 0.3s ease-in;
}

.footer__dev-link:hover {
  color: #735147;
}

.footer__dev-link::after {
  content: "";

  position: absolute;
  left: 50%;
  bottom: -20px;

  width: 67px;
  height: 13px;

  background-image: url(@/assets/img/menu-icon.svg);
  background-repeat: no-repeat;

  transform: translate(-50%);
}

@media screen and (max-width: 1919.98px) {
  .footer {
    padding-top: 26px;
    padding-bottom: 33px;
  }

  .footer::after {
    width: 38px;
    height: 22px;
  }

  .footer__top {
    margin-bottom: 21px;
  }

  .footer__menu {
    width: 595px;
  }

  .footer__logo {
    margin: 0 46px 0 46px;
  }

  .footer__contacts {
    width: 595px;
  }
}

@media screen and (max-width: 1599.98px) {
  .footer {
    padding-top: 19px;
    padding-bottom: 43px;
  }

  .footer::after {
    width: 32px;
    height: 18px;
  }

  .footer__top {
    margin-bottom: 13px;
  }

  .footer__menu {
    width: 462px;
  }

  .footer__logo {
    margin: 0 27px 0 27px;
  }

  .footer__contacts {
    width: 462px;
    padding-left: 25px;
  }
}

@media screen and (max-width: 1199.98px) {
  .footer {
    padding-top: 10px;
    padding-bottom: 30px;
  }

  .footer__top {
    margin-bottom: 12px;
  }

  .footer__menu {
    width: 410px;
  }

  .footer__logo {
    margin: 0 23px 0 23px;
  }

  .footer__contacts {
    width: 410px;
    padding-left: 25px;
  }
}

@media screen and (max-width: 991.98px) {
  .footer__top {
    justify-content: space-between;
    margin-bottom: 15px;
  }

  .footer__menu {
    display: none;
  }

  .footer__logo {
    margin: 0;
  }

  .footer__contacts {
    flex-direction: column;
    align-items: flex-end;
    width: auto;
    padding-left: 0;
  }

  .footer__phone {
    margin-right: 0;
  }

  .footer__phone a {
    display: block;
    text-align: right;
  }

  .footer__phone a::after {
    display: none;
  }

  .footer__social {
    display: none;
  }
}

@media screen and (max-width: 767.98px) {
  .footer__bottom {
    flex-direction: column;
    align-items: flex-start;
  }

  .footer__сopyright {
    margin-bottom: 4px;
  }
}
</style>
