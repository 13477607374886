<template>
  <section class="reviews" id="reviews">
    <div class="container">
      <Title2 class="reviews__title" v-html="getValue('title')" />
      <div class="reviews__wrapper swiper">
        <ul class="reviews__list swiper-wrapper">
          <ItemReviews class="reviews__item swiper-slide" v-for="item in getValue('list')" :key="item.text"
            :content="item" />
        </ul>
      </div>
      <div class="reviews__inner">
        <Button class="reviews__button" v-html="getValue('btn.text')" @click="popup" />
        <div class="reviews__controls">
          <ButtonControls class="reviews__controls-prev button-controls_prev" />
          <ButtonControls class="reviews__controls-next" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Title2 from "@/components/element/Title2App.vue";
import Button from "@/components/element/ButtonApp.vue";
import ButtonControls from "@/components/element/ButtonControls.vue";
import ItemReviews from "@/components/block/item/ItemReviews.vue";
import { getValue } from '@/helpers/index.js';
import Swiper, { Navigation } from "swiper";
import "swiper/css";
import SimpleBar from 'simplebar';
import 'simplebar/dist/simplebar.css';

export default {
  name: 'RecommendApp',
  components: {
    Title2,
    Button,
    ItemReviews,
    ButtonControls
  },
  props: {
    content: {
      // Определяет данные для отображения
      required: true,
      default: () => ({}),
    },
  },
  computed: {
    getValue() {
      return (path) => {
        return getValue(this.content, path, "");
      }
    },
  },
  methods: {
    popup() {
      this.$store.commit('updatePopup', {
        status: 'feedback',
        data: {
          popup: this.getValue('popup'),
          success: this.getValue('success'),
        }
      });
    }
  },
  mounted() {
    new Swiper(".reviews__wrapper", {
      modules: [Navigation],
      loop: true,
      speed: 500,
      navigation: {
        nextEl: ".reviews__controls-next",
        prevEl: ".reviews__controls-prev",
      },
      on: {
        init: function (swiper) {
          swiper.slides.forEach(element => {
            let item = element.querySelector('.item-reviews__content')
            new SimpleBar(item);
          });
        },
      },
      breakpoints: {
        320: {
          spaceBetween: 15,
        },
        767: {
          spaceBetween: 20,
          slidesPerView: 1,
        },
        992: {
          spaceBetween: 30,
          slidesPerView: 2,
        },
      },
    });
  },
};
</script>

<style>
.reviews {
  position: relative;
}

.reviews__title {
  margin-bottom: 50px;

  text-transform: uppercase;
  text-align: center;
}

.reviews__wrapper {
  margin-bottom: 45px;
}

.reviews__inner {
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;
}

.reviews__controls {
  position: absolute;
  right: 0;

  display: flex;
  justify-content: space-between;
  width: 196px;
}

.simplebar-track.simplebar-vertical {
  position: absolute;
  right: -37px;
  top: 0px;
  width: 12px;
  border: 1px solid #735147;

  background-color: #1f2637;
}

.simplebar-scrollbar::before {
  opacity: 1;
  background-color: #735147;
  border-radius: 0;
}

.simplebar-track.simplebar-vertical .simplebar-scrollbar::before {
  top: 4px;
  bottom: 6px;
}

.simplebar-scrollbar.simplebar-visible::before {
  opacity: 1;
}

@media screen and (max-width: 1919.98px) {
  .reviews__title {
    margin-bottom: 45px;
  }

  .reviews__controls {
    width: 166px;
  }

  .simplebar-track.simplebar-vertical {
    right: -32px;
  }
}

@media screen and (max-width: 1599.98px) {
  .reviews__title {
    margin-bottom: 40px;
  }

  .reviews__wrapper {
    margin-bottom: 40px;
  }

  .reviews__controls {
    width: 150px;
  }

  .simplebar-track.simplebar-vertical {
    right: -26px;
    width: 11px;
  }
}

@media screen and (max-width: 1199.98px) {
  .reviews__title {
    margin-bottom: 35px;
  }

  .reviews__wrapper {
    margin-bottom: 35px;
  }

  .simplebar-track.simplebar-vertical {
    right: -26px;
    width: 11px;
  }
}

@media screen and (max-width: 991.98px) {
  .reviews__title {
    margin-bottom: 15px;
  }

  .reviews__wrapper {
    margin-bottom: 30px;
  }

  .reviews__inner {
    display: flex;
    flex-direction: column;
  }

  .reviews__button {
    order: 1;
  }

  .reviews__controls {
    position: static;

    width: 110px;
    margin-bottom: 30px;
  }

  .simplebar-track.simplebar-vertical {
    right: -25px;
    width: 8px;
  }
}

@media screen and (max-width: 767.98px) {
  .reviews__title {
    margin-bottom: 25px;
  }

  .reviews__wrapper {
    margin-bottom: 25px;
  }

  .reviews__controls {
    margin-bottom: 25px;
  }
}
</style>
