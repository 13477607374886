<template>
  <a class="logo" href="#" v-scroll-to="'#top'">
    <img class="logo__img" src="@/assets/img/logo.png" width="126" height="76" alt="Логотип кафе Портос">
  </a>
</template>

<script>
export default {
  name: 'LogoApp'
};
</script>

<style>
.logo__img {
  display: block;
}

@media screen and (max-width: 1919.98px) {
  .logo__img {
    width: 116px;
    height: 70px;
  }
}

@media screen and (max-width: 1599.98px) {
  .logo__img {
    width: 100px;
    height: 60px;
  }
}

@media screen and (max-width: 991.98px) {
  .logo__img {
    width: 79px;
    height: 48px;
  }
}
</style>
