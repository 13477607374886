<template>
  <label class="input-data" :class="classObject">
    <input class="input-data__field" :class="{'input-data__field input-data has-value': modelValue}" type="date"
      :placeholder="getPlaceholder" :value="modelValue" @input="$emit('update:modelValue', $event.target.value)" />
    <span class="input-data_icon"></span>
    <span class="visually-hidden">{{ label }}</span>
  </label>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: ""
    },
    placeholder: {
      type: String,
      default: ""
    },
    required: {
      type: Boolean,
      default: false
    },
    status: {
      type: String,
      default: ""
    },
    modelValue: {
      type: String,
      default: ""
    },
  },
  emits: ["update:modelValue"],
  data() {
    return {
      classObject: {
        'input-data_errors': this.status == "error" ? true : false,
        'input-data_success': this.status == "success" ? true : false,
      }
    }
  },
  computed: {
    getPlaceholder() {
      if (this.status == "error") {
        return 'Поле “' + this.placeholder + '*” не указано';
      } else {
        return this.required ? this.placeholder + "*" : this.placeholder;
      }
    }
  }
};
</script>

<style>
.input-data {
  position: relative;
  z-index: 0;

  width: 100%;

  overflow: hidden;
}

.input-data::after {
  content: "";

  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;

  width: 100%;
  height: 1px;

  background-color: rgba(255, 255, 255, 0.3);
  z-index: 1;
}

.input-data::before {
  content: "";

  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 2;

  width: 0;
  height: 1px;

  background-color: #ffffff;

  transition: width 0.5s ease;
}

.input-data:focus-within::before {
  width: 100%;
}

.input-data_success::before {
  width: 100%;

  background-color: #4fba70;
}

.input-data_errors::before {
  width: 100%;

  background-color: #d92641;
}

.input-data_icon {
  position: absolute;
  right: 11px;
  top: 3px;

  width: 21px;
  height: 21px;
  border-radius: 50%;

  background-color: #d92641;
  background-image: url(@/assets/img/errors.svg);
  background-position: center;
  background-repeat: no-repeat;

  transform: translateX(300%);
  transition: transform 0.3s ease;
}

.input-data_errors .input-data_icon {
  transform: translateX(0);
}

.input-data__field {
  position: relative;

  display: block;
  width: 100%;
  padding: 0 32px 15px 15px;
  border: none;

  font-family: 'Montserrat', Tahoma, sans-serif;
  font-size: 14px;
  line-height: 1.5;
  color: #929292;

  background-color: transparent;

  outline: none;
  box-shadow: none;
  appearance: none;

}

.input-data__field::-webkit-calendar-picker-indicator {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: auto;
  height: auto;
  color: transparent;
  background: transparent;
}

.input-data_errors .input-data__field::placeholder {
  color: #d92641;
}


input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-clear-button {
  z-index: -10;
}

input[type="date"]::-webkit-input-placeholder {
  z-index: 99;
}

input[type="date"]:placeholder {
  z-index: 99;
}

input[type="date"]:not(.has-value):before {
  content: attr(placeholder);
  width: 100%;
}

@media screen and (max-width: 1599.98px) {
  .input-data_icon {
    right: 10px;
    top: 4px;

    width: 18px;
    height: 18px;
  }

  .input-data__field {
    padding: 0 32px 15px 15px;
    font-size: 12px;
  }
}

@media screen and (max-width: 1199.98px) {
  .input-data_icon {
    right: 8px;
    top: 5px;

    width: 17px;
    height: 17px;
  }

  .input-data__field {
    padding: 0 24px 12px 12px;
  }
}

@media screen and (max-width: 767.98px) {
  .input-data__field {
    padding: 0 24px 10px 10px;
  }
}
</style>
