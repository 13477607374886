<template>
  <div class="count">
    <label class="count_field">
      <span class="visually-hidden">Количество продуктов</span>
      <input class="count__input" type="number" placeholder="Кол-во персон" :value="modelValue"
        @input="changeValue($event)">
    </label>
    <button class="count__remove" type="button" v-bind:disabled="modelValue === 1" @click="reducingСounter()">
      <span class="visually-hidden">Уменьшить</span>
      <svg width="21" height="1" viewBox="0 0 21 1" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd"
          d="M0 0.5C0 0.223858 0.223858 0 0.5 0L20.5 0C20.7761 0 21 0.223858 21 0.5C21 0.776142 20.7761 1 20.5 1L0.500001 1C0.223858 1 0 0.776142 0 0.5Z"
          fill="#BCBCBC" />
      </svg>
    </button>
    <button class="count__add" type="button" @click="increasingСounter()">
      <span class="visually-hidden">Увеличить</span>
      <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd"
          d="M0 10.5C0 10.2239 0.223858 10 0.5 10L20.5 10C20.7761 10 21 10.2239 21 10.5C21 10.7761 20.7761 11 20.5 11L0.500001 11C0.223858 11 0 10.7761 0 10.5Z"
          fill="#BCBCBC" />
        <path fill-rule="evenodd" clip-rule="evenodd"
          d="M10.5 21C10.2239 21 10 20.7761 10 20.5V0.5C10 0.223858 10.2239 0 10.5 0C10.7761 0 11 0.223858 11 0.5V20.5C11 20.7761 10.7761 21 10.5 21Z"
          fill="#BCBCBC" />
      </svg>
    </button>
  </div>
</template>

<script>
export default {
  name: "CountApp",
  props: {
    modelValue: {
      // Определяет переменную привязки данных для v-model
      type: [String, Number],
      default: "",
    },
  },
  methods: {
    reducingСounter() {
      if (this.modelValue > 1) this.$emit('update:modelValue', this.modelValue - 1);
    },
    increasingСounter() {
      this.$emit('update:modelValue', this.modelValue + 1);
    },
    changeValue(e) {
      if (e.target.modelValue < 1) {
        this.$emit('update:modelValue', 1);
        return;
      }

      this.$emit('update:modelValue', e.target.value);
    }
  },
};
</script>

<style scoped>
.count {
  position: relative;
  z-index: 0;

  display: flex;
  width: 100%;

  overflow: hidden;
}

.count_field {
  width: 100%;
}

.count::after {
  content: "";

  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;

  width: 100%;
  height: 1px;

  background-color: rgba(255, 255, 255, 0.3);
  z-index: 1;
}

.count::before {
  content: "";

  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 2;

  width: 0;
  height: 1px;

  background-color: #ffffff;

  transition: width 0.5s ease;
}

.count:focus-within::before {
  width: 100%;
}

.count_success::before {
  width: 100%;

  background-color: #4fba70;
}

.count_errors::before {
  width: 100%;

  background-color: #d92641;
}

.count__input {
  display: block;
  width: 100%;
  padding: 0 15px 15px 15px;
  border: none;

  font-family: 'Montserrat', Tahoma, sans-serif;
  font-size: 14px;
  line-height: 1.5;
  color: #929292;

  background-color: transparent;

  outline: none;
  box-shadow: none;
  appearance: none;
}

.count__input:disabled {
  opacity: 0.6;
  cursor: default;
}

.count__input::-webkit-outer-spin-button,
.count__input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.count__input {
  -moz-appearance: textfield;
}

.count__remove {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 21px;
  height: 21px;
  padding: 0;
  margin-right: 20px;
  border: none;

  background-color: transparent;

  cursor: pointer;
  user-select: none;
  appearance: none;
}

.count__remove:disabled {
  cursor: default;
}

.count__add {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 21px;
  height: 21px;
  padding: 0;
  border: none;

  background-color: transparent;

  cursor: pointer;
  user-select: none;
  appearance: none;
}

.count__add:disabled {
  cursor: default;
}

.count__add-icon {
  display: block;
}
</style>
